import React, { useState, useEffect, useRef, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Grid, Button, Fab } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
// import SimpleDialog from "./addPartyType";
import Layout from "../layout";
import EditIcon from "@material-ui/icons/Edit";
import { useQuery, useMutation } from "@apollo/client";
import { GET_PERMISSIONS, GET_MODULES } from "../../utils/graphQL/queries";
import { DEL_PERMISSION } from "../../utils/graphQL/mutations";
import Spinners from "../../components/common/spinners";
// import { DEL_PARTY_TYPE } from "../../utils/graphQL/mutations";
import DeleteIcon from "@material-ui/icons/Delete";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import { tableStatus } from "../../styles/styles";
import PageRefresh from "../container/pageRefresh";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import RefreshIcon from "@material-ui/icons/Refresh";
import AddPermission from "../permissions/addPermission";
import DeletePermission from "../../components/permissions/delPermission";
import { DelAlertContext, AddAlertContext } from "../../common/store";
import AlertForm from "../../components/common/alert";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    height: "500px",
  },
  IconSize: {
    height: "20px",
    width: "35px",
    marginRight: "10px",
    cursor: "pointer",
  },
  button: {
    textTransform: "none",
  },
});

export default function ViewPermissions() {
  const classes = useStyles();
  const tableStatusColor = tableStatus();
  const [values, setValues] = useState();
  const permissionRef = useRef({});
  const delRef = useRef({});
  const [open, setOpen] = React.useState(false);
  const [dopen, setdOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [queryData, setqueryData] = useState({});
  const { loading: queryLoading, error, data, refetch } = useQuery(
    GET_PERMISSIONS
  );
  const {
    data: dataModules,
    error: errorModules,
    loading: loadingModules,
  } = useQuery(GET_MODULES);
  const [delAlertValue, setDelAlertValue] = useContext(DelAlertContext);
  const [addAlertValue, setAddAlertValue] = useContext(AddAlertContext);

  let datas = { data };
  const loading = queryLoading;

  console.log(datas);

  const handleClickOpen = (row) => {
    permissionRef.current.row = row;
    var titleSetter = permissionRef.current.row
      ? permissionRef.current.row.id
      : null;
    if (titleSetter !== null) {
      setTitle("Edit Permission");
    } else {
      setTitle("Add Permission");
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelClose = () => {
    setdOpen(false);
  };

  const handleClickDelete = (e, row) => {
    e.preventDefault();
    delRef.current.row = row;
    setdOpen(true);
  };

  const handleRefresh = async () => {
    try {
      const res = await refetch();
      if (res.loading === true) {
        console.log(res.loading);
      } else if (res.data.permissions.length > 0) {
        console.log(res.data.permissions.length, res.data.permissions);
        return (datas = res.data.permissions);
      } else {
        console.log("not working");
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (error) return console.log(error), (<p>Error :(</p>);

  return (
    <Layout>
      <div>
        {(delAlertValue && (
          <AlertForm title="Permission has been Deleted Successfully" />
        )) ||
          (addAlertValue && (
            <AlertForm title="Permission has been Added / Updated Successfully" />
          ))}
        <Grid container>
          <Grid item>
            <h3>List of Permissions</h3>
          </Grid>
          <Grid item style={{ marginLeft: "auto" }}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ marginRight: "10px", textTransform: "none" }}
              startIcon={<RefreshIcon />}
              onClick={handleRefresh}
            >
              Refresh
            </Button>

            <Button
              variant="contained"
              color="primary"
              size="small"
              className={classes.button}
              startIcon={<SaveIcon />}
              onClick={() => handleClickOpen(null)}
            >
              Add permission
            </Button>
          </Grid>
        </Grid>

        <TableContainer className={classes.table} component={Paper}>
          <Table size="small" aria-label="a dense table" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Module</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading && !datas.data ? (
                <span>
                  <Spinners />
                </span>
              ) : (
                datas.data.permissions.length &&
                datas.data.permissions.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {row.name ? row.name : null}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.is_active ? (
                        <RadioButtonCheckedIcon
                          className={tableStatusColor.active}
                        />
                      ) : (
                        <RadioButtonCheckedIcon
                          className={tableStatusColor.inactive}
                        />
                      )}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.parentid ? row.parentid : null}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <EditIcon
                        color="secondary"
                        aria-label="edit"
                        className={classes.IconSize}
                        onClick={() => handleClickOpen(row)}
                      />

                      <DeleteIcon
                        color="primary"
                        aria-label="add"
                        className={classes.IconSize}
                        onClick={(e) => handleClickDelete(e, row)}
                      />
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <DeletePermission
          open={dopen}
          onClose={handleDelClose}
          title="Delete a Permission ?"
          ref={delRef}
        />

        <AddPermission
          open={open}
          onClose={handleClose}
          datas={dataModules}
          ref={permissionRef}
          title={title}
        />
      </div>
      <br />
      <div className={tableStatusColor.display}>
        <RadioButtonCheckedIcon className={tableStatusColor.active} />
        Active
        <RadioButtonCheckedIcon className={tableStatusColor.inactive} />
        Not-Active
        <RadioButtonCheckedIcon className={tableStatusColor.nullValue} />
        Not Available
      </div>
    </Layout>
  );
}
