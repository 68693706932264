export default function compareIgnoreCase(a, b) {
  a = a || "";
  b = b || "";
  if (typeof a == "string" || typeof b == "string") {
    let r1 = a.toLowerCase();
    let r2 = b.toLowerCase();
    if (r1 < r2) {
      return -1;
    }
    if (r1 > r2) {
      return 1;
    }
  }
  if (typeof a == "number" || typeof b == "number") {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
  }
  if (typeof a == "boolean" || typeof b == "boolean") {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
  }
}
