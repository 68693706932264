import React, { useState } from "react";
// import Avatar from "@material-ui/core/Avatar";
// import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
// import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
// import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
// import { Face, Fingerprint } from "@material-ui/icons";
import { Button, Link, Paper } from "@material-ui/core";
// import { Redirect } from "react-router-dom";
import { useStyles } from "../styles/styles";
// import InputAdornment from "@material-ui/core/InputAdornment";
// import VisibilityIcon from "@material-ui/icons/Visibility";
// import IconButton from "@material-ui/core/IconButton";
// import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
// import { checkNetwork } from "../common/regex";
import {useParams} from 'react-router-dom'
import ForgotPasswordForm from "../components/resetPassword/forgotPasswordForm";

export default function ForgotPassword(props) {
    const { token } = useParams() // fetch token for reset password
    const urlParams = new URLSearchParams(props.location.search)
    // const redirect = urlParams.get('redirect') // true if its SAMS button will activate
    // const rtoUrl = urlParams.get('rtoUrl') // redirect to url
    const party = urlParams.get('party') // redirect to url

    const classes = useStyles();
   
    const [loading, setLoading] = React.useState(false);
    const [passwordReset, setPasswordReset] = React.useState(false)

    return (
        <div>
        <Container component="main" maxWidth="xs" className="d-flex align-items-center">
            <CssBaseline />
            <Paper>
            <div className={classes.paper}>
                <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                style={{ paddingTop: "50px" }}
                >
                    <Typography component="h1" variant="h4" ><strong>{party  ?? "Compatio"}</strong></Typography><br/>
                {/* <img
                    src={`${process.env.PUBLIC_URL}/assets/logo.png`}
                    style={{ width: "50px", height: "50px" }}
                /> */}
                </Grid>

                {
                    !passwordReset ? 
                    <ForgotPasswordForm
                        classes={classes}
                        loading={loading}
                        setLoading={setLoading}
                        setPasswordReset={setPasswordReset}
                        token = {token}
                    />
                    :
                    <Container>
                        <Typography component="h1" variant="h5" align="center">
                            Reset your password
                        </Typography>
                        <br/>
                        <Typography  component="h1">
                            Check your mail for a link to reset your password. If it doesn't appear within a few minutes, check your spam folder. 
                        </Typography>
                        {/* <Button
                                type="button"
                                fullWidth
                                variant="contained"
                                color="primary"
                                href = {"/login"}
                                className={classes.submit}
                                // onClick={submitHandler}
                            >
                                Return to sign in
                            </Button> */}
                    </Container>
                }
                
            </div>
            <Box mt={8}>
                <Typography variant="body2" color="textSecondary" align="center">
                {"Copyright © "}
                Compatio
                <span>&nbsp;&nbsp;</span> {new Date().getFullYear()}
                {"."}
                </Typography>
            </Box>
            </Paper>
        </Container>
        </div>
    );
}
