import React, { useState, useEffect, useRef, useContext } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button, Fab } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
// import SimpleDialog from "./addPartyType";
import Layout from "../layout";
import EditIcon from "@material-ui/icons/Edit";
import { useQuery, useMutation, NetworkStatus } from "@apollo/client";
import { GET_USERS } from "../../utils/graphQL/queries";
import { DEL_USER } from "../../utils/graphQL/mutations";
import Spinners from "../../components/common/spinners";
// import { DEL_PARTY_TYPE } from "../../utils/graphQL/mutations";
import DeleteIcon from "@material-ui/icons/Delete";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import { tableStatus } from "../../styles/styles";
import RefreshIcon from "@material-ui/icons/Refresh";
import DeleteUser from "../../components/users/delUser";
import { Link } from "react-router-dom";
import AddUserParty from "../userParty/addUserParty";
import TableComponent from "../../components/common/table/table";
import AlertNotify from "../../components/common/snackBar";
import * as val from "../../common/regex";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    height: "500px",
  },
  IconSize: {
    height: "20px",
    width: "35px",
    marginRight: "10px",
    cursor: "pointer",
  },
  button: {
    textTransform: "none",
  },
});

export default function ViewUsers() {
  let history = useHistory();
  const classes = useStyles();
  const tableStatusColor = tableStatus();
  const [values, setValues] = useState();
  const partyRef = useRef({});
  const delRef = useRef({});
  const [open, setOpen] = React.useState(false);
  const [dopen, setdOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [queryData, setqueryData] = useState({});
  const {
    loading: queryLoading,
    error,
    data,
    refetch,
    networkStatus,
  } = useQuery(GET_USERS, {
    notifyOnNetworkStatusChange: true,
  });
  const [deleteUser, { loading: removeLoading }] = useMutation(DEL_USER);
  const [openAlert, setopenAlert] = useState(false);
  const [alertMessage, setalertMessage] = useState();
  const [loader, setloader] = useState(false);
  const [Severity, setSeverity] = useState(false);

  let datas = { data };
  const loading = queryLoading || removeLoading;

  const handleClickOpen = (row) => {
    partyRef.current.row = row;
    partyRef.current.title = "Edit User";
    history.push({
      pathname: "/users/edit",
      state: { data: partyRef.current },
      title: "Edit User",
    });
  };

  const manageUserParty = (row) => {
    setValues(row);
    history.push("/users/party/manage", row);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelClose = () => {
    setdOpen(false);
  };

  const handleClickDelete = (e, row) => {
    e.preventDefault();
    delRef.current.row = row;
    setdOpen(true);
  };
  const handleAlert = (params) => {
    setopenAlert((openAlert) => !openAlert);
  };
  const handleRefresh = async () => {
    try {
      const res = await refetch(GET_USERS);
      if (!res.queryLoading && res.data.users.length > 0) {
        return (datas = res.data.users);
      } else {
        console.log("Something went wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    return () => {};
  }, [datas]);

  const tableHeaders = [
    {
      Header: "Email ID",
      accessor: "email",
      width: "20%",
    },
    {
      Header: "Name",
      accessor: (row) => `${row.first_name} ${row.last_name}`,
      Cell: ({ cell }) => {
        let firstName = "";
        let lastName = "";
        if (val.isEmpty(cell.row.original.first_name)) {
          firstName = "";
        } else {
          firstName = cell.row.original.first_name;
        }
        if (val.isEmpty(cell.row.original.last_name)) {
          lastName = "";
        } else {
          lastName = cell.row.original.last_name;
        }
        return firstName + " " + lastName;
      },
      width: "15%",
    },
    {
      Header: "Contact",
      accessor: "mobile",
      disableFilters: true,
      disableSortBy: true,
      width: "5%",
    },
    {
      Header: "Association",
      accessor: "association",
      Cell: ({ cell }) => (
        <Button
          variant="contained"
          color="primary"
          size="small"
          className={classes.button}
          // startIcon={<SaveIcon />}
          // component={Link}
          // to={"/users/party/manage"}
          onClick={() => manageUserParty(cell.row.original)}
        >
          Associate
        </Button>
      ),
      disableFilters: true,
      disableSortBy: true,
      width: "12%",
    },
    {
      Header: "Status",
      accessor: "is_active",
      Cell: ({ cell }) =>
        cell.row.values.is_active ? (
          <RadioButtonCheckedIcon className={tableStatusColor.active} />
        ) : (
          <RadioButtonCheckedIcon className={tableStatusColor.inactive} />
        ),
      disableFilters: true,
      disableSortBy: true,
      width: "10%",
    },
    {
      Header: "Verified Email",
      accessor: "is_emailverified",
      Cell: ({ cell }) =>
        cell.row.values.is_emailverified ? (
          <RadioButtonCheckedIcon className={tableStatusColor.active} />
        ) : (
          <RadioButtonCheckedIcon className={tableStatusColor.inactive} />
        ),
      disableFilters: true,
      disableSortBy: true,
      width: "5%",
    },
    {
      Header: "Social Account",
      accessor: "is_social",
      Cell: ({ cell }) =>
        cell.row.values.is_social ? (
          <RadioButtonCheckedIcon className={tableStatusColor.active} />
        ) : (
          <RadioButtonCheckedIcon className={tableStatusColor.inactive} />
        ),
      disableFilters: true,
      disableSortBy: true,
      width: "5%",
    },
    {
      Header: "Actions",
      accessor: "actions",
      Cell: ({ cell }) => (
        <div class="aux">
          <EditIcon
            color="primary"
            aria-label="edit"
            className={classes.IconSize}
            onClick={() => handleClickOpen(cell.row.original)}
          />
          <DeleteIcon
            color="secondary"
            aria-label="add"
            className={classes.IconSize}
            onClick={(e) => handleClickDelete(e, cell.row.original)}
          />
        </div>
      ),
      disableFilters: true,
      disableSortBy: true,
      width: "10%",
    },
  ];

  let tableData = null;
  if (!loading && datas.data) {
    tableData = datas.data.users;
  }

  if (error) return console.log(error), (<p>Error :(</p>);

  return (
    <Layout>
      <div>
        <AlertNotify
          openAlert={openAlert}
          handleAlert={handleAlert}
          message={alertMessage}
          Severity={Severity}
        />
        <Grid container>
          <Grid item>
            <h3>List of Users</h3>
          </Grid>
          <Grid item style={{ marginLeft: "auto" }}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              className={classes.button}
              style={{ marginRight: "10px" }}
              startIcon={<RefreshIcon />}
              onClick={handleRefresh}
            >
              Refresh
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              className={classes.button}
              startIcon={<SaveIcon />}
              component={Link}
              to={"/users/add"}
              // onClick={() => handleClickOpen(null)}
            >
              Add User
            </Button>
          </Grid>
          <Grid item xs={12}>
            {loader && <Spinners />}
            {networkStatus === NetworkStatus.refetch ? (
              <Spinners />
            ) : loading && !datas.data ? (
              <Spinners />
            ) : (
              <TableComponent
                tableHeaders={tableHeaders}
                tableData={tableData}
              />
            )}
          </Grid>
          <DeleteUser
            open={dopen}
            onClose={handleDelClose}
            title="Delete a User ?"
            ref={delRef}
            setopenAlert={setopenAlert}
            setalertMessage={setalertMessage}
            setloader={setloader}
            setSeverity={setSeverity}
          />
        </Grid>
      </div>
      <br />
      <div className={tableStatusColor.display}>
        <RadioButtonCheckedIcon className={tableStatusColor.active} />
        Active
        <RadioButtonCheckedIcon className={tableStatusColor.inactive} />
        Not-Active
        <RadioButtonCheckedIcon className={tableStatusColor.nullValue} />
        Not Available
      </div>
    </Layout>
  );
}
