import React, { useRef, forwardRef } from "react";
import PropTypes from "prop-types";
import AddUserPartyComponent from "../../components/userParty/addUserParty";
import Layout from "../layout";

function AddUserParty(props, ref) {
  const user = props.location.state;
  return (
    <Layout>
      <AddUserPartyComponent user={user} />
    </Layout>
  );
}

AddUserParty.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default forwardRef(AddUserParty);
