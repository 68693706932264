import { Button, Grid, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import AuthManager from "../../common/AuthManager";
import * as val from "../../common/regex";
import SAMSAPIService from "../../api/SAMSService";
import { useHistory } from "react-router-dom";
import Spinners from "../common/spinners";



export default function ResetPasswordForm ({
    classes,
    loading,
    setLoading,
    setPasswordReset,
    token
}) {
    let history = useHistory();
    let timeOut = useRef()

    const [showPassword, setShowPassword] = useState(false);
    const [errorMessage, seterrorMessage] = React.useState("");
    const [disabled, setDisabled] = React.useState(true)

    const [email, setEmail] = React.useState("");

    const changeHandler = (e) => {
        e.preventDefault();
        setEmail(e.target.value);
    };

    const validate_email = (inputemail) => {
		const re =
			/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (re.test(inputemail)) return true;
		return false;
	}

    const submitHandler = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {
            if(!validate_email(email)) throw new Error("")

            const result = await SAMSAPIService.doPost(
                `user/password/recover`,
                { email },
                null
            );
            
            if(result.error) throw new Error(result.message)

            setPasswordReset(true)
        } catch (error) {
            seterrorMessage(error.message);
        }
        setLoading(false)
    }


    const handleShowPassword = (e) => {
        e.preventDefault()
        setShowPassword(!showPassword)
    }

    return (
        <>
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ paddingTop:"20px" }}
            >
            <center>
            <Typography component="h1" variant="h5">
                Trouble logging in?
            </Typography>
            </center>
        </Grid>
        
        {loading && <Spinners />}
        <p style={{maxWidth: "356px"}}>
        Please fill in your email address so we can email you a link to reset your password.
        </p>
        <form className={classes.form} noValidate>

            <Grid container spacing={2} alignItems="flex-end">
                {/* <Grid item>
                <Face className={classes.IconSize} />
                </Grid> */}
                <Grid item md={true} sm={true} xs={true}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    id="email"
                    label="Email"
                    name="email"
                    autoComplete="off"
                    size="small"
                    fullWidth
                    autoFocus
                    value={email}
                    type={"email"}
                    onChange={changeHandler}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") submitHandler(e);
                    }}
                />
                </Grid>
            </Grid>

            {errorMessage !== "" && (
            <div className={classes.errorMessage}>
                <br /> <center>{errorMessage}</center>
            </div>
            )}
            
            <Button
                type="button"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={submitHandler}
                disabled={loading}
            >
                Send password reset email
            </Button>

        </form>
    </>
    )
}