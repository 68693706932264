import React, { useState, useEffect, useRef, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button, Fab } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
// import SimpleDialog from "./addPartyType";
import Layout from "../layout";
import EditIcon from "@material-ui/icons/Edit";
import { useQuery, useMutation, NetworkStatus } from "@apollo/client";
import {
  GET_ROLES,
  GET_MODULES,
  GET_PERMISSIONS,
  GET_PARTIES,
  GET_PRODUCTS,
} from "../../utils/graphQL/queries";
import { DEL_USER } from "../../utils/graphQL/mutations";
import Spinners from "../../components/common/spinners";
import TableComponent from "../../components/common/table/table";
// import { DEL_PARTY_TYPE } from "../../utils/graphQL/mutations";
import DeleteIcon from "@material-ui/icons/Delete";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import { tableStatus } from "../../styles/styles";
import RefreshIcon from "@material-ui/icons/Refresh";
import Addroles from "./../roles/addRoles";
import DeleteRole from "../../components/roles/delRole";
import { RolesPermission } from "../../common/store";

import AlertNotify from "../../components/common/snackBar";
import RolesPermissionView from "../rolesPermissions/view";
import SearchBar from "../../components/roles/components/searchBar";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  IconSize: {
    height: "20px",
    width: "35px",
    marginRight: "10px",
    cursor: "pointer",
  },
  button: {
    textTransform: "none",
  },
});
export default function Viewroles(props) {
  const classes = useStyles();
  const tableStatusColor = tableStatus();
  const roleRef = useRef({});
  const delRef = useRef({});
  const assignRef = useRef({});
  const [open, setOpen] = React.useState(false);
  const [dopen, setdOpen] = useState(false);
  const [title, setTitle] = useState("");
  const {
    loading: queryLoading,
    error,
    data,
    refetch,
    networkStatus,
  } = useQuery(GET_ROLES, {
    notifyOnNetworkStatusChange: true,
  });
  const [deleteUser, { loading: removeLoading }] = useMutation(DEL_USER);
  const [rolesPermission, setRolesPermission] = useContext(RolesPermission);
  const [activeRole, setactiveRole] = React.useState();
  const [activeProduct, setactiveProduct] = React.useState();
  const [tableRender, settableRender] = useState();
  const {
    data: dataPermissions,
    error: errorPermissions,
    loading: loadingPermissions,
    networkStatus: networkStatusPermissions,
  } = useQuery(GET_PERMISSIONS, {
    notifyOnNetworkStatusChange: true,
  });
  const {
    data: dataProductList,
    error: errorProductList,
    loading: loadingProductList,
    networkStatus: networkStatusProducts,
  } = useQuery(GET_PRODUCTS, {
    notifyOnNetworkStatusChange: true,
  });
  let datas = { data };

  let activeRoles;

  let {
    data: dataPartyList,
    error: errorPartyList,
    loading: loadingPartyList,
    networkStatus: networkStatusParties,
  } = useQuery(GET_PARTIES, {
    notifyOnNetworkStatusChange: true,
  });
  const [openAlert, setopenAlert] = useState(false);
  const [alertMessage, setalertMessage] = useState();
  const [loader, setloader] = useState(false);
  const [Severity, setSeverity] = useState(false);
  const loading = queryLoading || removeLoading;

  React.useEffect(() => {
    refetch(GET_PARTIES);
    return () => {};
  }, [alertMessage]);

  const handleClickOpen = (row) => {
    roleRef.current.row = row;
    var titleSetter = roleRef.current.row ? roleRef.current.row.id : null;
    if (titleSetter !== null) {
      setTitle("Edit Role");
    } else {
      setTitle("Add Role");
    }
    setOpen(true);
  };

  const handleAssignOpen = (e, row) => {
    e.preventDefault();
    assignRef.current.row = row;
    setRolesPermission(row);
    props.history.push(`/roles/${row.id}/permissons`);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleDelClose = () => {
    setdOpen(false);
  };
  const handleClickDelete = (e, row) => {
    e.preventDefault();
    delRef.current.row = row;
    setdOpen(true);
  };

  const handleAlert = (params) => {
    setopenAlert((openAlert) => !openAlert);
  };

  const handleRefresh = async () => {
    try {
      const res = await refetch();
      if (res.loading === true) {
      } else if (res.data.roles.length > 0) {
        return (datas = res.data.roles);
      } else {
      }
    } catch (error) {}
  };

  useEffect(() => {
    return () => {};
  }, [datas]);

  if (error) return <span>error</span>;

  if (!loading && datas.data) {
    activeRoles = datas.data.roles;
  }

  if (activeRole != undefined && activeRole != "") {
    activeRoles = activeRoles.filter((row) => {
      return activeRole === row.party_id;
    });
  }

  if (activeProduct != undefined && activeProduct != "") {
    activeRoles = activeRoles.filter((row) => {
      return activeProduct === row.product_id;
    });
  }

  const tableHeaders = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Product Name",
      accessor: (row) => {
        if (row.product_name[0] != undefined || row.product_name[0] != null) {
          return row.product_name[0].name;
        } else {
          return "";
        }
      },
      width: "20%",
    },
    {
      Header: "Status",
      accessor: "is_active",
      Cell: ({ cell }) =>
        cell.row.values.is_active ? (
          <RadioButtonCheckedIcon className={tableStatusColor.active} />
        ) : (
          <RadioButtonCheckedIcon className={tableStatusColor.inactive} />
        ),
      disableFilters: true,
      disableSortBy: true,
      width: "10%",
    },
    {
      Header: "Role to Permission",
      accessor: "id",
      Cell: ({ cell }) => (
        <Button
          variant="contained"
          color="primary"
          size="small"
          style={{ textTransform: "none" }}
          onClick={(e) => handleAssignOpen(e, cell.row.original)}
        >
          Assign
        </Button>
      ),
      disableFilters: true,
      disableSortBy: true,
      width: "20%",
    },
    {
      Header: "Actions",
      accessor: "actions",
      Cell: ({ cell }) => (
        <div className="aux">
          <EditIcon
            color="primary"
            aria-label="edit"
            className={classes.IconSize}
            onClick={() => handleClickOpen(cell.row.original)}
          />
          <DeleteIcon
            color="secondary"
            aria-label="add"
            className={classes.IconSize}
            onClick={(e) => handleClickDelete(e, cell.row.original)}
          />
        </div>
      ),
      disableFilters: true,
      disableSortBy: true,
    },
  ];
  let tableData = null;

  if (!loading && datas.data) {
    tableData = datas.data.roles;
  }

  return (
    <Layout>
      <div>
        <AlertNotify
          openAlert={openAlert}
          handleAlert={handleAlert}
          message={alertMessage}
          Severity={Severity}
        />
        {/* Dont Delete below Function. Might need in Future */}
        {/* <Grid container spacing={2}>
          <Grid item xs={1} style={{ marginTop: "inherit" }}>
            <h3>Search</h3>
          </Grid>
          <Grid item xs={4} sm={4} style={{ marginLeft: "0px" }}>
            <SearchBar
              data={dataPartyList && dataPartyList.parties}
              activeRole={activeRole}
              setactiveRole={setactiveRole}
              label="Search Party"
            />
          </Grid>
          <Grid item xs={4} sm={4}>
            <SearchBar
              data={dataProductList && dataProductList.product}
              activeRole={activeProduct}
              setactiveRole={setactiveProduct}
              label="Search Product"
            />
          </Grid>
        </Grid> */}
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <i className="icon-sort"></i>
            <h3>List of roles</h3>
          </Grid>

          <Grid item style={{ marginLeft: "auto" }}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ marginRight: "10px", textTransform: "none" }}
              startIcon={<RefreshIcon />}
              onClick={handleRefresh}
            >
              Refresh
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              className={classes.button}
              startIcon={<SaveIcon />}
              onClick={() => handleClickOpen(null)}
            >
              Add Role
            </Button>
          </Grid>
        </Grid>
        {/* 
        {loading && !datas.data ? (
          <span>
            <Spinners />
          </span>
        ) : !activeRoles.length ? (
          <span style={{ marginLeft: "15px" }}>No Record Found</span>
        ) : (
          <TableComponent tableHeaders={tableHeaders} tableData={tableData} />
        )} */}
        <Grid container>
          <Grid item xs>
            {loader && <Spinners />}
            {networkStatus === NetworkStatus.refetch ? (
              <Spinners />
            ) : loading ? (
              <Spinners />
            ) : (
              <TableComponent
                tableHeaders={tableHeaders}
                tableData={tableData}
              />
            )}
          </Grid>
        </Grid>
        <DeleteRole
          open={dopen}
          onClose={handleDelClose}
          title="Delete a User ?"
          ref={delRef}
          setopenAlert={setopenAlert}
          setalertMessage={setalertMessage}
          setloader={setloader}
          setSeverity={setSeverity}
        />
        <Addroles
          open={open}
          onClose={handleClose}
          datas={datas}
          permissions={dataPermissions}
          ref={roleRef}
          title={title}
          dataProductList={dataProductList}
          dataPartyList={dataPartyList}
          setopenAlert={setopenAlert}
          setalertMessage={setalertMessage}
          setSeverity={setSeverity}
        />
        {/* <RolesPermissionView ref = {assignRef}/> */}
      </div>
      <br />
      <div className={tableStatusColor.display}>
        <RadioButtonCheckedIcon className={tableStatusColor.active} />
        Active
        <RadioButtonCheckedIcon className={tableStatusColor.inactive} />
        Not-Active
        <RadioButtonCheckedIcon className={tableStatusColor.nullValue} />
        Not Available
      </div>
    </Layout>
  );
}
