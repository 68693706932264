import { gql } from "@apollo/client";

export const ADD_PARTY_TYPE = gql`
  mutation addEditPartyType($id: Int, $name: String!, $is_active: Boolean) {
    addEditPartyType(id: $id, name: $name, is_active: $is_active) {
      id
    }
  }
`;

export const DEL_PARTY_TYPE = gql`
  mutation deletePartyType($id: Int!) {
    deletePartyType(id: $id) {
      deleted
    }
  }
`;

export const ADD_EDIT_PARTY = gql`
  mutation addEditParty(
    $id: Int
    $name: String!
    $type_id: Int!
    $is_active: Boolean
    $address: String
  ) {
    addEditParty(
      id: $id
      name: $name
      type_id: $type_id
      is_active: $is_active
      address: $address
    ) {
      name
    }
  }
`;

export const DEL_PARTY = gql`
  mutation deleteParty($id: Int!) {
    deleteParty(id: $id) {
      deleted
    }
  }
`;

export const ADD_EDIT_ROLE = gql`
  mutation addEditRole(
    $id: Int
    $name: String!
    $is_active: Boolean
    $product_id: Int!
  ) {
    addEditRole(
      id: $id
      name: $name
      is_active: $is_active
      product_id: $product_id
    ) {
      name
    }
  }
`;

export const DEL_ROLE = gql`
  mutation deleteRole($id: Int!) {
    deleteRole(id: $id) {
      deleted
    }
  }
`;

export const ADD_EDIT_MODULE = gql`
  mutation addEditModule($id: Int, $name: String!, $is_active: Boolean) {
    addEditModule(id: $id, name: $name, is_active: $is_active) {
      name
    }
  }
`;

export const DEL_MODULE = gql`
  mutation deleteModule($id: Int!) {
    deleteModule(id: $id) {
      deleted
    }
  }
`;

export const ADD_EDIT_PERMISSION = gql`
  mutation addEditPermission(
    $id: Int
    $name: String!
    $parentid: Int!
    $is_active: Boolean
  ) {
    addEditPermission(
      id: $id
      name: $name
      parentid: $parentid
      is_active: $is_active
    ) {
      name
    }
  }
`;

export const DEL_PERMISSION = gql`
  mutation deletePermission($id: Int!) {
    deletePermission(id: $id) {
      deleted
    }
  }
`;

export const ADD_EDIT_ROLE_PERMISSION = gql`
  mutation addEditRolePermission(
    $role_id: Int!
    $permission_id: Int!
    $is_active: Boolean
  ) {
    addEditRolePermission(
      role_id: $role_id
      permission_id: $permission_id
      is_active: $is_active
    ) {
      id
    }
  }
`;

export const ADD_EDIT_USER = gql`
  mutation addEditUser(
    $id: Int
    $email: String!
    $first_name: String
    $last_name: String
    $mobile: String
    $password: String
    $is_active: Boolean
    $address: String
    $token: String
  ) {
    addEditUser(
      id: $id
      email: $email
      first_name: $first_name
      last_name: $last_name
      mobile: $mobile
      password: $password
      is_active: $is_active
      address: $address
      token: $token
    ) {
      email
    }
  }
`;

export const DEL_USER = gql`
  mutation deleteUser($id: Int!) {
    deleteUser(id: $id) {
      deleted
    }
  }
`;

export const ADD_EDIT_USER_PARTY = gql`
  mutation addEditUserParty(
    $user_id: Int
    $party_id: Int
    $user_type_id: [Int]
    $role_id: [Int]
    $user_party_id: Int
    $is_active: Boolean
  ) {
    addEditUserParty(
      user_id: $user_id
      party_id: $party_id
      user_type_id: $user_type_id
      role_id: $role_id
      user_party_id: $user_party_id
      is_active: $is_active
    ) {
      id
      error
    }
  }
`;

export const ADD_EDIT_USER_CHANNEL = gql`
  mutation addEditUserSubscription(
    $user_id: Int
    $id: Int
    $party_id: Int
    $user_type_id: [Int]
    $subscription_id: Int
    $user_party_id: Int
    $is_active: Boolean
  ) {
    addEditUserSubscription(
      user_id: $user_id
      id: $id
      party_id: $party_id
      user_type_id: $user_type_id
      subscription_id: $subscription_id
      user_party_id: $user_party_id
      is_active: $is_active
    ) {
      id
      error
    }
  }
`;

export const ADD_EDIT_USER_INDUSTRY = gql`
  mutation addEditUserIndustry(
    $user_id: Int
    $id: Int
    $industry_id: Int
    $is_active: Boolean
  ) {
    addEditUserIndustry(
      user_id: $user_id
      id: $id
      industry_id: $industry_id
      is_active: $is_active
    ) {
      id
      error
    }
  }
`;

export const DEL_USER_PARTY = gql`
  mutation deleteUserParty($id: Int!) {
    deleteUserParty(id: $id) {
      deleted
    }
  }
`;
