import React, { forwardRef, useState, useEffect, useContext } from 'react';
import {
	TextField,
	FormControl,
	Button,
	CssBaseline,
	FormControlLabel,
	Grid,
	Container,
	Radio,
	RadioGroup,
	FormLabel,
} from '@material-ui/core';
import { useMutation } from '@apollo/client';
import { ADD_EDIT_MODULE } from '../../utils/graphQL/mutations';
import { GET_MODULES } from '../../utils/graphQL/queries';
import { addPartyTypeComponent } from '../../styles/styles';
import { AddAlertContext } from '../../common/store';
import Spinners from '../common/spinners';
import ConfirmationDialog from '../common/confirmationDialog';
import * as val from '../../common/regex';

export function AddModule(props, ref) {
	const classes = addPartyTypeComponent();
	const initialState = {
		partyType: '',
		activateAccount: '',
	};

	const [name, setName] = useState(initialState);
	const [open, setOpen] = useState(false);
	const [spinners, setSpinners] = useState(false);
	const [addEditModule, { errors, data }] = useMutation(ADD_EDIT_MODULE, {
		errorPolicy: 'all',
	});
	const [errMessage, seterrMessage] = useState('');
	var id = ref.current.row ? ref.current.row.id : null;
	var radioStatus = ref.current.row ? ref.current.row.is_active : true;
	const [caption, setCaption] = useState('');
	const [isActive, setIsActive] = useState(`${radioStatus}`);
	const [bol, setBol] = useState(radioStatus);
	const [updateButton, setupdateButton] = useState(true);
	const [dumRef, setdumRef] = useState(ref.current.row);

	useEffect(() => {
		if (id === null) {
			setCaption('save');
		} else {
			setCaption('update');
		}
	}, []);
	const handleClickOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
		setName({ ...initialState });
	};
	const handleClickClose = e => {
		e.preventDefault();
		props.onClose();
	};
	const handleName = e => {
		e.preventDefault();
		setName({ ...name, [e.target.name]: e.target.value });
	};
	const handleRadioButton = e => {
		e.persist();
		if (e.target.value === 'true') {
			setBol(true);
		} else if (e.target.value === 'false') {
			setBol(false);
		}
	};

	const submitter = async (name, stat) => {
		const response = await addEditModule({
			variables: {
				name: name,
				is_active: stat,
			},
			refetchQueries: [{ query: GET_MODULES }],
			awaitRefetchQueries: true,
		});
		if (response.errors) {
			seterrMessage(response.errors[0].message);
			setSpinners(false);
			handleClose();
		} else {
			seterrMessage('');
			props.onClose();
			handleClose();
			setSpinners(false);
			props.setopenAlert(true);
			props.setalertMessage('New Module has been Added');
			props.setSeverity(false);
		}
		return;
	};

	const handleSubmit = async e => {
		e.preventDefault();
		var id = ref.current.row ? ref.current.row.id : null;
		setSpinners(true);

		if (caption === 'save') {
			const nameValidation = val.validate_input(name.moduleName, 'Module Name', 'name', 50);
			if (nameValidation) {
				seterrMessage(nameValidation);
				setSpinners(false);
				handleClose();
			} else {
				submitter(name.moduleName, bol);
			}
		} else if (caption === 'update') {
			const validateName = val.validate_input(document.getElementById('moduleName').value, 'Module Name', 'name', 50);
			if (validateName) {
				seterrMessage(validateName);
				setSpinners(false);
				handleClose();
			} else {
				const response = await addEditModule({
					variables: {
						id: dumRef.id,
						name: name.moduleName ? name.moduleName : dumRef.name,
						is_active: bol,
					},
					// variables: { id: id, name: name.moduleName, is_active: bol },
					refetchQueries: [{ query: GET_MODULES }],
					awaitRefetchQueries: true,
				});
				if (response.errors) {
					seterrMessage(response.errors[0].message);
					setSpinners(false);
					handleClose();
				} else {
					seterrMessage('');
					props.onClose();
					handleClose();
					setSpinners(false);
					props.setopenAlert(true);
					props.setalertMessage('Module has been Updated');
					props.setSeverity(false);
				}
			}
		}

		ref.current.row = null;
	};
	return (
		<div>
			{spinners && <Spinners />}
			<Container component="main" maxWidth="xs">
				<div style={{ color: 'red' }}>{errMessage}</div>
				<CssBaseline />
				<div className={classes.paper}>
					<form className={classes.form} noValidate>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<TextField
									autoComplete="moduleName"
									name="moduleName"
									variant="outlined"
									id="moduleName"
									label="Module Name"
									fullWidth
									required
									autoFocus
									defaultValue={ref.current.row ? ref.current.row.name : ''}
									onChange={handleName}
								/>
							</Grid>
							<Grid item xs={12}>
								<FormControl component="fieldset">
									<FormLabel component="legend">Status</FormLabel>
									<RadioGroup
										aria-label="activateAccount"
										name="activateAccount"
										id="activateAccount"
										defaultValue={isActive}
										onChange={handleRadioButton}
									>
										<FormControlLabel value="true" control={<Radio />} label="Enable" />
										<FormControlLabel value="false" control={<Radio />} label="Disable" />
									</RadioGroup>
								</FormControl>
							</Grid>
						</Grid>
						<Grid container justify="flex-end" spacing={2} className={classes.submitIcon}>
							<Grid item>
								<Button
									variant="contained"
									color="secondary"
									size="small"
									style={{ marginRight: '10px' }}
									className={classes.button}
									onClick={handleClickClose}
								>
									Cancel
								</Button>
								<Button
									variant="contained"
									color="primary"
									size="small"
									className={classes.button}
									onClick={handleClickOpen}
									disabled={!updateButton}
								>
									{caption}
								</Button>
							</Grid>
						</Grid>
						<ConfirmationDialog
							open={open}
							handleClose={handleClose}
							handleSubmit={handleSubmit}
							dialogTitle={id ? 'Are you sure you want to update Module ?' : 'Are you sure you want to Add Module ?'}
						/>
					</form>
				</div>
			</Container>
		</div>
	);
}
export default forwardRef(AddModule);
