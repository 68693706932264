import { Button, Grid, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import AuthManager from "../../common/AuthManager";
import * as val from "../../common/regex";
import SAMSAPIService from "../../api/SAMSService";
import { useHistory } from "react-router-dom";



export default function ResetPasswordForm ({
    classes,
    loading,
    setLoading,
    setPasswordReset,
    token,
    party
}) {
    let history = useHistory();
    let timeOut = useRef()

    const [showPassword, setShowPassword] = useState(false);
    const [errorMessage, seterrorMessage] = React.useState("");
    const [disabled, setDisabled] = React.useState(true)

    const initialState = {
        password: "",
        confirm_password: "",
    };
    const [value, setValue] = React.useState(initialState);

    const changeHandler = (e) => {
        e.preventDefault();
        setValue({ ...value, [e.target.name]: e.target.value });
    };

    const validate_password = (inputpassword) => {
        var regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
        if (inputpassword.match(regex)) return true;
        return false;
    }

    useEffect(() => {
        seterrorMessage("")
        clearTimeout(timeOut.current)
        timeOut.current = setTimeout(() => {
            try{
                if(value.password === "" || value.confirm_password === "") throw new Error("")
                if(value.password !== value.confirm_password) throw new Error("Mismatched password")
                if(!validate_password(value.password)) throw new Error("Your password should contain 8-15 characters mixed with Lowercase, Uppercase, Symbol and Number")

                setDisabled(false)
            }
            catch(err){
                seterrorMessage(err.message)
                setDisabled(true)
            }
        }, 1000)
        
    }, [value])

    const submitHandler = async (e) => {
        setLoading(true)
        try {
            if(value.password === "" || value.confirm_password === "") throw new Error("Password is empty")
            if(!validate_password(value.password)) throw new Error("Your password should contain 8-15 characters mixed with Lowercase, Uppercase, Symbol and Number")
            if(value.password !== value.confirm_password) throw new Error("Mismatched password")

            const result = await SAMSAPIService.doPost(
                `user/password/recover/${token}`,
                { password: value.password },
                null
            );
            
            if(result.error) throw new Error(result.message)

            setPasswordReset(true)
        } catch (error) {
            seterrorMessage(error.message);
        }
        setLoading(false);
    }


    const handleShowPassword = (e) => {
        if(e.target.checked) setShowPassword(true)
        else setShowPassword(false)        
    }

    return (
        <>
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ paddingTop:"20px" }}
            >
            <center>
                <Typography component="h1" variant="h6">
                    Reset password
                </Typography>
            </center>
        </Grid>
        <form className={classes.form} noValidate>
            <Grid container spacing={2} alignItems="flex-end">
                {/* <Grid item>
                <Face className={classes.IconSize} />
                </Grid> */}
                <Grid item md={true} sm={true} xs={true}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    id="password"
                    label="Password"
                    name="password"
                    autoComplete="off"
                    size="small"
                    fullWidth
                    autoFocus
                    value={value.password}
                    type={showPassword ? "text" : "password"}
                    onChange={changeHandler}
                    onKeyDown={(e) => {
                      if (e.key === "Enter")
                        document.getElementById("confirm_password").focus();
                    }}
                />
                </Grid>
            </Grid>

            <Grid container spacing={2} alignItems="flex-end">
                {/* <Grid item>
                <Fingerprint className={classes.IconSize} />
                </Grid> */}
                <Grid item md={true} sm={true} xs={true}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="confirm_password"
                    label="Confirm Password"
                    type={showPassword ? "text" : "password"}
                    id="confirm_password"
                    autoComplete="off"
                    size="small"
                    value={value.confirm_password.trim()}
                    onChange={changeHandler}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") submitHandler();
                    }}
                />
                </Grid>

            </Grid>
            <div className="MuiButtonBase-root">
                <input onChange={handleShowPassword} type="checkbox" id="show-password" name="show-password" checked={showPassword}/>
                <label style={{paddingLeft: "5px"}} htmlFor="show-password">Show password</label>
            </div>

            {errorMessage !== "" && (
            <div className={classes.errorMessage}>
                <br /> <center>{errorMessage}</center>
            </div>
            )}
            
            <Button
                type="button"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={submitHandler}
                disabled={loading || disabled}
            >
                Change Password
            </Button>
        </form>
    </>
    )
}