import React, { forwardRef, useState, useContext } from "react";
import { GET_MODULES } from "../../utils/graphQL/queries";
import { DEL_MODULE } from "../../utils/graphQL/mutations";
import { useMutation } from "@apollo/client";
import Spinners from "../common/spinners";
import ConfirmationDialog from "../common/confirmationDialog";

export function DeleteModule(props, ref) {
  const {
    onClose,
    open,
    title,
    setopenAlert,
    setalertMessage,
    setloader,
    setSeverity,
  } = props;

  const [deleteModule, { loading }] = useMutation(DEL_MODULE);
  const [errorMessage, seterrorMessage] = useState(false);
  const [spinners, setSpinners] = useState(false);

  const handleDelete = async () => {
    setSpinners(true);
    var id = ref.current.row ? ref.current.row.id : null;
    const response = await deleteModule({
      variables: { id: id },
      refetchQueries: [{ query: GET_MODULES }],
      awaitRefetchQueries: true,
    });
    if (response.data.deleteModule.deleted === true) {
      seterrorMessage(false);
      setSpinners(false);
      setopenAlert(true);
      setalertMessage("Module has been Deleted");
      setSeverity(true);
    } else {
      seterrorMessage(true);
      setSpinners(false);
    }
    props.onClose();
    setloader(false);
  };

  return (
    <div>
      {/* {spinners && <Spinners />} */}
      <ConfirmationDialog
        open={open}
        handleClose={onClose}
        handleSubmit={handleDelete}
        dialogTitle="Are you sure you want to Delete Module ?"
      />
    </div>
  );
}

export default forwardRef(DeleteModule);
