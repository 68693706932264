import React, { useState, useEffect } from "react";
import ToggleButton from "../../common/toggleButton";
import { useMutation } from "@apollo/client";
import { ADD_EDIT_USER_CHANNEL } from "../../../utils/graphQL/mutations";
// import { GET_ROLE_PERMISSIONS } from "../../utils/graphQL/queries";
import Spinners from "../../common/spinners";

import {
  TextField,
  makeStyles,
  useTheme,
  FormControl,
  Button,
  CssBaseline,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Container,
  Paper,
  Typography,
  Divider,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormGroup,
  Checkbox,
  TableRow,
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableHead,
} from "@material-ui/core";

export default function AddEditChannelToggle({
  allChannels,
  isChecked,
  userChannels,
  userDetails,
  partyid,
  setUserstate,
}) {
  var clone = null;

  const [checked, setChecked] = useState(isChecked);
  const [spin, setspin] = useState(false);
  // const [addEditUserParty, { loading, errors, data }] = useMutation(
  //   ADD_EDIT_USER_PARTY,
  //   {
  //     errorPolicy: "all",
  //   }
  // );

  const [addEditUserChannel, { loading, errors, data }] = useMutation(
    ADD_EDIT_USER_CHANNEL,
    {
      errorPolicy: "all",
    }
  );

  const [array2, setarray2] = useState();
  const [loader, setloader] = useState(false);

  useEffect(() => {
    if (allChannels) {
      // clone = [...allChannels];
      // clone.active = true;
      // setarray2(clone);
      console.log("updated channels")
      getAvailableRoles(allChannels, userChannels);
    }
    return () => {};
  }, [userChannels]);

  const handleToggle = async (e, act, upi, ri, clone) => {
    setloader(true);
    setUserstate(false);
    if (act === true) {
      clone.active = false;
      setChecked(false);
      setUserstate(false);
      setspin(true);
      const response = await addEditUserChannel({
        variables: {
          user_id: userDetails.user_id,
          party_id: userDetails.party_id,
          user_type_id: userDetails.user_type_id,
          is_active: clone.active,
          subscription_id: ri,
        },
      });
      setUserstate(true);
      if (response) setloader(false);
    } else {
      clone.active = true;
      setChecked(true);
      setspin(true);
      setUserstate(false);
      const response = await addEditUserChannel({
        variables: {
          user_id: userDetails.user_id,
          party_id: userDetails.party_id,
          user_type_id: userDetails.user_type_id,
          is_active: clone.active,
          subscription_id: ri,
        },
        // refetchQueries: [
        //   { query: GET_ROLE_PERMISSIONS, variables: { id: roleId } },
        // ],
        // awaitRefetchQueries: true,
      });
      setUserstate(true);
      if (response) setloader(false);
    }
  };

  const getAvailableRoles = (allChannels, userChannels) => {
    const userChannelIds = userChannels.map(ch => ch.subscription_id)
    const availableChannels = allChannels?.filter(ch => (ch.party_id === partyid && !userChannelIds?.includes(ch.id)))
    setarray2([...availableChannels]);
  };
  return (
    <div>
      <TableContainer style={{ padding: "15px" }}>
        <Table size="small" aria-label="a dense table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Channel Name</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loader ? (
              <Spinners />
            ) : (
              array2 &&
              array2.map((d, index) => (
                <TableRow>
                  <TableCell component="th" scope="row">
                    {d.name}
                  </TableCell>
                  <TableCell>
                    <FormControlLabel
                      control={
                        <ToggleButton
                          defaultChecked={isChecked}
                          onChange={(e) =>
                            handleToggle(e, d.active, userChannels.id, d.id, d)
                          }
                          name={d.name}
                          id={d.id}
                          value={d.id}
                        />
                      }
                      // label={roles.name}
                    />
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* {!roles ? (
        <Spinners />
      ) : (
        <FormControlLabel
          control={
            <ToggleButton
              defaultChecked={isChecked}
              onChange={(e) =>
                handleToggle(e, roles.active, userChannels.id, roles.id, roles)
              }
              name={roles.name}
              id={roles.id}
              value={roles.id}
            />
          }
          // label={roles.name}
        />
      )} */}
    </div>
  );
}
