import React, { forwardRef, useState, useContext } from "react";
import { GET_PARTY_TYPE } from "../../utils/graphQL/queries";
import { DEL_PARTY_TYPE } from "../../utils/graphQL/mutations";
import { useMutation } from "@apollo/client";
import ConfirmationDialog from "../common/confirmationDialog";

export function DelPartyType(props, ref) {
  const {
    onClose,
    open,
    title,
    setopenAlert,
    setalertMessage,
    setloader,
    setSeverity,
  } = props;

  const [deletePartyType, { loading }] = useMutation(DEL_PARTY_TYPE);
  const [errorMessage, seterrorMessage] = useState(false);
  const [spinners, setSpinners] = useState(false);

  const handleDelete = async () => {
    setSpinners(true);
    var id = ref.current.row ? ref.current.row.id : null;
    const response = await deletePartyType({
      variables: { id: id },
      refetchQueries: [{ query: GET_PARTY_TYPE }],
      awaitRefetchQueries: true,
    });
    if (response.data.deletePartyType.deleted === true) {
      seterrorMessage(false);
      setSpinners(false);
      setopenAlert(true);
      setloader(true);
      setalertMessage("Party Type has been Deleted");
      setSeverity(true);
    } else {
      seterrorMessage(true);
      setSpinners(false);
    }
    props.onClose();
    setloader(false);
  };

  return (
    <div>
      {/* {spinners && <Spinners />} */}
      <ConfirmationDialog
        open={open}
        handleClose={onClose}
        handleSubmit={handleDelete}
        dialogTitle="Are you sure you want to Delete Party Type ?"
      />
    </div>
  );
}

export default forwardRef(DelPartyType);
