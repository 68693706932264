import React, { useState, useRef } from "react";
import { makeStyles, Grid, Button, Fab } from "@material-ui/core";
import Layout from "../layout";
import AddParty from "./addParty";
import SaveIcon from "@material-ui/icons/Save";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { useQuery, NetworkStatus } from "@apollo/client";
import { GET_PARTIES, GET_PARTY_TYPE } from "../../utils/graphQL/queries";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import { tableStatus } from "../../styles/styles";
import RefreshIcon from "@material-ui/icons/Refresh";
import DeleteParty from "../../components/parties/delParty";
import Spinners from "../../components/common/spinners";
import TableComponent from "../../components/common/table/table";
import AlertNotify from "../../components/common/snackBar";

const useStyles = makeStyles({
  // table: {
  //   minWidth: 650,
  //   height: "500px",
  // },
  IconSize: {
    height: "20px",
    width: "35px",
    marginRight: "10px",
    cursor: "pointer",
  },
  button: {
    textTransform: "none",
  },
});

export default function ViewParties() {
  const classes = useStyles();
  const tableStatusColor = tableStatus();
  const partyRef = useRef({});
  const delRef = useRef({});
  const [open, setOpen] = React.useState(false);
  const [dopen, setdOpen] = useState(false);
  const [title, setTitle] = useState("");
  const {
    loading: loadingParties,
    error,
    data: dataParties,
    refetch,
    networkStatus,
  } = useQuery(GET_PARTIES, {
    notifyOnNetworkStatusChange: true,
  });
  const {
    data: dataPartyTypes,
    error: errorPartyTypes,
    loading: loadingPartyTypes,
    networkStatus: typeNetworkStatus,
  } = useQuery(GET_PARTY_TYPE, {
    notifyOnNetworkStatusChange: true,
  });

  const [openAlert, setopenAlert] = useState(false);
  const [alertMessage, setalertMessage] = useState();
  const [loader, setloader] = useState(false);
  const [Severity, setSeverity] = useState(false);

  let loading = loadingParties || loadingPartyTypes;
  let datas = { dataParties };

  React.useEffect(() => {
    refetch(GET_PARTIES);
    return () => {};
  }, [alertMessage]);

  const handleClickOpen = (row) => {
    partyRef.current.row = row;
    var titleSetter = partyRef.current.row ? partyRef.current.row.id : null;
    if (titleSetter !== null) {
      setTitle("Edit Party");
    } else {
      setTitle("Add Party");
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelClose = () => {
    setdOpen(false);
  };
  const handleClickDelete = (row) => {
    delRef.current.row = row;
    setdOpen(true);
  };
  const handleAlert = (params) => {
    setopenAlert((openAlert) => !openAlert);
  };
  const handleRefresh = async () => {
    try {
      const res = await refetch();
      if (res.loading === true) {
        console.log(res.loading);
      } else if (res.data.parties !== undefined) {
        console.log(res.data.parties.length, res.data.parties);
        return (datas = res.data.parties);
      } else {
        console.log("not working");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const tableHeaders = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Party Type",
      accessor: (row) => {
        if (row.party_type[0] != undefined || row.party_type[0] != null) {
          return row.party_type[0].name;
        } else {
          return "";
        }
      },
    },
    {
      Header: "Status",
      accessor: "is_active",
      Cell: ({ cell }) =>
        cell.row.values.is_active ? (
          <RadioButtonCheckedIcon className={tableStatusColor.active} />
        ) : (
          <RadioButtonCheckedIcon className={tableStatusColor.inactive} />
        ),
      disableFilters: true,
      disableSortBy: true,
    },

    {
      Header: "Actions",
      accessor: "actions",
      Cell: ({ cell }) => (
        <div class="aux">
          <EditIcon
            color="primary"
            aria-label="edit"
            className={classes.IconSize}
            onClick={() => handleClickOpen(cell.row.original)}
          />
          <DeleteIcon
            color="secondary"
            aria-label="add"
            className={classes.IconSize}
            onClick={() => handleClickDelete(cell.row.original)}
          />
        </div>
      ),
      disableFilters: true,
      disableSortBy: true,
    },
  ];

  let tableData = null;

  if (!loading && datas) {
    tableData = dataParties.parties;
  }

  if (error) return console.log(error), (<p>Error :(</p>);

  return (
    <Layout>
      <div>
        <AlertNotify
          openAlert={openAlert}
          handleAlert={handleAlert}
          message={alertMessage}
          Severity={Severity}
        />
        <Grid container>
          <Grid item>
            <h3>List of Parties</h3>
          </Grid>
          <Grid item style={{ marginLeft: "auto" }}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ marginRight: "10px", textTransform: "none" }}
              startIcon={<RefreshIcon />}
              onClick={handleRefresh}
            >
              Refresh
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              className={classes.button}
              startIcon={<SaveIcon />}
              onClick={() => handleClickOpen(null)}
            >
              Add Party
            </Button>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs>
            {loader && <Spinners />}
            {networkStatus === NetworkStatus.refetch ? (
              <Spinners />
            ) : loading ? (
              <Spinners />
            ) : (
              <TableComponent
                tableHeaders={tableHeaders}
                tableData={tableData}
              />
            )}
          </Grid>
        </Grid>

        <AddParty
          open={open}
          onClose={handleClose}
          datas={dataPartyTypes}
          ref={partyRef}
          title={title}
          setopenAlert={setopenAlert}
          setalertMessage={setalertMessage}
          setSeverity={setSeverity}
        />
        <DeleteParty
          open={dopen}
          onClose={handleDelClose}
          title="Delete a Party ?"
          ref={delRef}
          setopenAlert={setopenAlert}
          setalertMessage={setalertMessage}
          setloader={setloader}
          setSeverity={setSeverity}
        />
      </div>
      <br />
      <div className={tableStatusColor.display}>
        <RadioButtonCheckedIcon className={tableStatusColor.active} />
        Active
        <RadioButtonCheckedIcon className={tableStatusColor.inactive} />
        Not-Active
        <RadioButtonCheckedIcon className={tableStatusColor.nullValue} />
        Not Available
      </div>
    </Layout>
  );
}
