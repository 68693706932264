class Utils {
  static isEmpty(value) {
    return (
      value == null || value == undefined || value.toString().trim() === ""
    );
  }

  static isNumeric(num) {
    return !isNaN(num);
  }
  static trimWhiteSpace(str) {
    return str.replace(/\s+/g, "");
  }
  static hasWhiteSpace(string) {
    // return /\s/g.test(str);
    if (string.length == string.replace(" ", "").length) {
      return false;
    }
    return true;
  }
}

export default Utils;
