import React from "react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import App from "../app";
import SAMSService from "../api/SAMSService";
import AuthManager from "../common/AuthManager";

const httpLink = createHttpLink({
  uri: SAMSService.url + "graphql",
});

const authLink = setContext((_, { headers }) => {
  const token = AuthManager.getToken();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default function ApolloRoot() {
  return (
    <div>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </div>
  );
}
