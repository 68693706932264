import React, {
  forwardRef,
  useState,
  useRef,
  useEffect,
  useContext,
} from "react";
import {
  TextField,
  makeStyles,
  useTheme,
  FormControl,
  Button,
  CssBaseline,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Container,
} from "@material-ui/core";
import { useMutation } from "@apollo/client";
import { AddAlertContext } from "../../common/store";
import { addPartyComponent } from "../../styles/styles";
import { ADD_EDIT_PERMISSION } from "../../utils/graphQL/mutations";
import { GET_PERMISSIONS, GET_MODULES } from "../../utils/graphQL/queries";
import Spinners from "../common/spinners";
import * as val from "../../common/regex";
import ConfirmationDialog from "../common/confirmationDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(2),
    },
  },
  formGroup: {
    "& > *": {
      margin: theme.spacing(1),
      //   width: 200
    },
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  submitIcon: {
    margin: theme.spacing(2, 0, 2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
    maxWidth: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

function EditPermission(props, ref) {
  const { datas, rowMap } = props;
  var moduleId = ref.current.row ? ref.current.row.id : "";
  var moduleNameVar = ref.current.row ? ref.current.row.name : "";
  const classes = useStyles();
  const theme = useTheme();
  const initialState = {
    partyName: "",
    partyType: "",
    partyEmail: "",
  };
  const [typeName, setTypeName] = useState("");
  const [moduleName, setModuleName] = useState(moduleId);
  // const [bol, setBol] = useState();
  const [optionsValue, setoptionsValue] = useState();
  const [results, setResults] = useState(datas.modules);
  const [open, setOpen] = useState(false);
  const [spinners, setSpinners] = useState(false);
  const [addEditPermission, { errors, data }] = useMutation(
    ADD_EDIT_PERMISSION,
    {
      errorPolicy: "all",
    }
  );
  const [errMessage, seterrMessage] = useState("");
  var id = ref.current.row ? ref.current.row.id : null;
  var permissionId = rowMap.current.row ? rowMap.current.row.id : null;
  var radioStatus = rowMap.current.row ? rowMap.current.row.is_active : true;
  const [caption, setCaption] = useState("");
  const [isActive, setIsActive] = useState(`${radioStatus}`);
  const [bol, setBol] = useState(radioStatus);
  const [updateButton, setupdateButton] = useState(true);
  const [dumRef, setdumRef] = useState(rowMap.current.row);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setTypeName("");
  };
  const handleClickClose = (e) => {
    e.preventDefault();
    setTypeName("");
    props.onClose();
  };
  const handleReset = (e) => {
    e.preventDefault();
  };
  const handleName = (e) => {
    e.preventDefault();
    setTypeName({ ...typeName, [e.target.name]: e.target.value });
  };
  const handleModuleName = (e, id) => {
    setModuleName(id);
  };
  const handleChange = (e) => {
    setoptionsValue(e.target.value);
  };
  const handleRadioButton = (e) => {
    e.persist();
    if (e.target.value === "true") {
      setBol(true);
    } else if (e.target.value === "false") {
      setBol(false);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSpinners(true);
    var pid = ref.current.row ? ref.current.row.id : null;
    setSpinners(true);
    const validateName = val.validate_input(
      document.getElementById("permissionName").value,
      "Permission Name",
      "name",
      15
    );
    if (validateName) {
      seterrMessage(validateName);
      setSpinners(false);
      handleClose();
    } else {
      const response = await addEditPermission({
        variables: {
          id: dumRef.id,
          name: typeName.permissionName ? typeName.permissionName : dumRef.name,
          parentid: moduleName,
          is_active: bol,
        },
        refetchQueries: [{ query: GET_PERMISSIONS }],
        awaitRefetchQueries: true,
      });
      if (response.errors) {
        seterrMessage(response.errors[0].message);
        setSpinners(false);
        handleClose();
      } else {
        seterrMessage("");
        props.onClose();
        handleClose();
        setSpinners(false);
        props.setopenAlert(true);
        props.setalertMessage("Permission has been Updated");
        props.setSeverity(false);
      }
    }
    setTypeName("");
    ref.current.row = null;
  };
  return (
    <div>
      {spinners && <Spinners />}
      <Container component="main" maxWidth="xs">
        <div style={{ color: "red" }}>{errMessage}</div>
        <CssBaseline />
        <div className={classes.paper}>
          <form className={classes.form} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="moduleName"
                  label="Module Name"
                  name="moduleName"
                  autoComplete="moduleName"
                  defaultValue={moduleNameVar}
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="permissionName"
                  label="Permission Name"
                  name="permissionName"
                  autoComplete="permissionName"
                  defaultValue={
                    rowMap.current.row ? rowMap.current.row.name : ""
                  }
                  value={typeName.permissionName}
                  onChange={handleName}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Status</FormLabel>
                  <RadioGroup
                    aria-label="activateAccount"
                    name="activateAccount"
                    id="activateAccount"
                    defaultValue={isActive}
                    onChange={handleRadioButton}
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label="Enable"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label="Disable"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              container
              justify="flex-end"
              spacing={2}
              className={classes.submitIcon}
            >
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  style={{ marginRight: "10px" }}
                  className={classes.button}
                  onClick={handleClickClose}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className={classes.button}
                  onClick={handleClickOpen}
                  disabled={!updateButton}
                >
                  update
                </Button>
              </Grid>
            </Grid>
            <ConfirmationDialog
              open={open}
              handleClose={handleClose}
              handleSubmit={handleSubmit}
              dialogTitle={"Are you sure you want to update Permission ?"}
            />
          </form>
        </div>
      </Container>
    </div>
  );
}
export default forwardRef(EditPermission);
