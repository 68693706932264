import React, { useState } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SaveIcon from "@material-ui/icons/Save";
import {
  TextField,
  makeStyles,
  useTheme,
  FormControl,
  Button,
  CssBaseline,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Container,
  Paper,
  Typography,
  Divider,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItem,
} from "@material-ui/core";
import { useMutation, useQuery } from "@apollo/client";
import { ADD_EDIT_USER_PARTY } from "../../../utils/graphQL/mutations";
import { GET_PARTY_ROLES } from "../../../utils/graphQL/queries";
import AccordionDrop from "./accordionDropDown";
import List from "@material-ui/core/List";

import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import CommentIcon from "@material-ui/icons/Comment";
import CheckboxField from "./checkbox";
import CheckboxList from "./list";
import Spinners from "../../common/spinners";
import Togg from "./dynamicTogg";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(18),
    // color: theme.palette.text.secondary,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const DynamicAccordion = (props, { user }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState("");
  const [checked, setChecked] = React.useState([0]);
  const [EmployeeIsTrue, setEmployeeIsTrue] = React.useState(false);
  const [CustomerIsTrue, setCustomerIsTrue] = React.useState(false);
  const [RolesState, setRolesState] = useState();
  const [PartyState, setPartyState] = useState();
  const [isDisabled, setisDisabled] = useState(false);
  const [spinners, setSpinners] = useState(false);
  const [partySelect, setPartySelect] = useState();
  const [errMessage, seterrMessage] = useState("");
  const [Message, setMessage] = useState(false);
  const [AccordionDisable, setAccordionDisable] = useState(false);
  const { loading, error, data, refetch } = useQuery(GET_PARTY_ROLES, {
    variables: {},
  });
  const [addEditUserParty, { errors, data: d1 }] = useMutation(
    ADD_EDIT_USER_PARTY,
    {
      errorPolicy: "all",
    }
  );

  const handleChange = (panel) => (evt, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleSubmit = async (e, row) => {
    setSpinners(true);
    setMessage(false);
    var testval = null;
    if (EmployeeIsTrue && CustomerIsTrue) testval = [1, 2];
    if (EmployeeIsTrue && !CustomerIsTrue) testval = [1];
    if (!EmployeeIsTrue && CustomerIsTrue) testval = [2];
    EmployeeIsTrue && setRolesState();
    var v = 0;
    var removeUnwanted = [1];
    if (RolesState !== undefined || null) {
      removeUnwanted = null;
      removeUnwanted = RolesState.filter((item) => item !== v);
    }
    const response = await addEditUserParty({
      variables: {
        user_id: props.user.id,
        party_id: PartyState,
        user_type_id: testval,
        role_id: removeUnwanted,
      },
    });
    if (response.errors) {
      setSpinners(false);
      if (
        response.errors[0].message === "Cannot read property 'length' of null"
      ) {
        seterrMessage("Please choose type of user");
      } else {
        seterrMessage(response.errors[0].message);
      }
      setisDisabled(false);
      setMessage(false);
    } else {
      seterrMessage("");
      setMessage(true);
      setSpinners(false);
      setisDisabled(true);
      setAccordionDisable(true);
    }
  };

  const handleCheckbox = (event) => {
    console.log(event.target.value);
    // this.res.add(value);
    // if (this.res.size === 3) console.log(this.res);
  };

  return (
    <div className={classes.root}>
      <Grid container xs={12}>
        <Accordion
          expanded={expanded === props.panelName}
          onChange={handleChange(props.panelName)}
          style={{ width: "inherit" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography className={classes.heading}>
              <AccordionDrop
                PartyState={PartyState}
                setPartyState={setPartyState}
                partySelect={partySelect}
                setPartySelect={setPartySelect}
                disabled={AccordionDisable}
              />
            </Typography>
            {spinners && <Spinners />}
            <br />
            {Message && (
              <Typography style={{ color: "#40d040" }}>
                Successfully Associated to User
              </Typography>
            )}
            <br />
            <Typography style={{ color: "red" }}>
              {errMessage && errMessage}
            </Typography>
          </AccordionSummary>
          {!partySelect ? (
            <Grid item xs={12}>
              <Typography style={{ color: "red", padding: "20px" }}>
                Please Choose a Party
              </Typography>
            </Grid>
          ) : (
            <AccordionDetails>
              <Typography>
                <h5>Type of User</h5>
              </Typography>
            </AccordionDetails>
          )}
          {partySelect && (
            <AccordionDetails>
              <Checkbox
                checked={EmployeeIsTrue}
                onChange={(e) => {
                  setEmployeeIsTrue(e.target.checked);
                }}
                value={1}
                inputProps={{
                  "aria-label": "secondary checkbox",
                }}
                onClick={handleCheckbox}
              />
              <div style={{ paddingTop: "10px" }}>Employee</div>
            </AccordionDetails>
          )}
          {partySelect && (
            <AccordionDetails>
              <Grid item xs={12}>
                {EmployeeIsTrue && (
                  <Togg
                    data={data}
                    user={user}
                    RolesState={RolesState}
                    setRolesState={setRolesState}
                  />
                  // <CheckboxList
                  //   data={data}
                  //   user={user}
                  //   RolesState={RolesState}
                  //   setRolesState={setRolesState}
                  // />
                )}
              </Grid>
            </AccordionDetails>
          )}
          {partySelect && (
            <AccordionDetails>
              <Checkbox
                checked={CustomerIsTrue}
                onChange={(e) => {
                  setCustomerIsTrue(e.target.checked);
                }}
                value={2}
                inputProps={{
                  "aria-label": "secondary checkbox",
                }}
                onClick={handleCheckbox}
              />
              <div style={{ paddingTop: "10px" }}>Customer</div>
            </AccordionDetails>
          )}
          {partySelect && (
            <AccordionDetails>
              {CustomerIsTrue && (
                <Typography style={{ paddingLeft: "20px" }}>
                  This User will be assigned with Customer role
                </Typography>
              )}
            </AccordionDetails>
          )}
          {partySelect && (
            <AccordionDetails>
              <Button
                variant="contained"
                color="primary"
                size="small"
                style={{ marginLeft: "auto" }}
                startIcon={<SaveIcon />}
                // component={Link}
                // to={"/users/party/manage"}
                // onClick={() => manageUserParty(row)}
                onClick={handleSubmit}
                disabled={isDisabled}
              >
                Save
              </Button>
            </AccordionDetails>
          )}
        </Accordion>
      </Grid>
    </div>
  );
};

export default DynamicAccordion;
