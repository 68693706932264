//dynamicTogg OG

import React from "react";
import {
  FormControlLabel,
  TableRow,
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableHead,
} from "@material-ui/core";
import ToggleButton from "../../common/toggleButton";

export default function ToggleButtonComponent(props) {
  var clone = null;
  const [checked, setChecked] = React.useState([0]);
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  React.useEffect(() => {
    props.setRolesState(checked);
  }, [checked]);

  return (
    <div>
      <TableContainer style={{ padding: "15px" }}>
        <Table size="small" aria-label="a dense table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Role Name</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data !== undefined && props.data.partyRole !== null ? (
              props.data.partyRole.map((d, index) => (
                <TableRow>
                  <TableCell component="th" scope="row">
                    {d.name}
                  </TableCell>
                  <TableCell>
                    <FormControlLabel
                      control={
                        <ToggleButton
                          defaultChecked={checked}
                          onChange={handleToggle(d.id)}
                          name={d.name}
                          id={d.id}
                          value={d.id}
                          checked={checked.indexOf(d.id) !== -1}
                          tabIndex={-1}
                        />
                      }
                      // label={roles.name}
                    />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <span>No Roles are associated to this party</span>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
