import React, { useState, useEffect } from "react";
import { FormControlLabel } from "@material-ui/core";
import ToggleButton from "../../common/toggleButton";
import { useMutation } from "@apollo/client";
import { ADD_EDIT_USER_CHANNEL, ADD_EDIT_USER_PARTY } from "../../../utils/graphQL/mutations";
// import { GET_ROLE_PERMISSIONS } from "../../utils/graphQL/queries";
import Spinners from "../../common/spinners";

export default function ChannelToggleComponent({
  rowMap,
  isChecked,
  row,
  setUserstate,
}) {
  var clone = null;
  const [roles, setroles] = useState();
  console.log(row)
  useEffect(() => {
    clone = JSON.parse(JSON.stringify(rowMap));
    clone.active = true;
    setroles(clone);
    return () => {};
  }, [rowMap]);

  const [checked, setChecked] = useState(isChecked);
  const [addEditUserChannel, { loading, errors, data }] = useMutation(
    ADD_EDIT_USER_CHANNEL,
    {
      errorPolicy: "all",
    }
  );
  const handleToggle = async (e, act, upi, ri, clone, id) => {
    setUserstate(false);
    if (act === true) {
      clone.active = false;
      setChecked(false);
      setroles(clone);
      setUserstate(false);
      const response = await addEditUserChannel({
        variables: {
          user_id: row.user_id,
          party_id: upi,
          subscription_id: ri,
          is_active: clone.active,
          id: id
        },
        // refetchQueries: [
        //   { query: GET_ROLE_PERMISSIONS, variables: { id: roleId } },
        // ],
        // awaitRefetchQueries: true,
      });
      setUserstate(true);
    } else {
      clone.active = true;
      setChecked(true);
      setroles(clone);
      setUserstate(false);
      const response = await addEditUserChannel({
        variables: {
          user_id: row.user_id,
          party_id: upi,
          subscription_id: ri,
          is_active: clone.active,
          id: id
        },
        // refetchQueries: [
        //   { query: GET_ROLE_PERMISSIONS, variables: { id: roleId } },
        // ],
        // awaitRefetchQueries: true,
      });
      setUserstate(true);
    }
  };
  useEffect(() => {
    return () => {};
  }, [roles]);

  return (
    <div>
      {!roles || loading ? (
        <Spinners />
      ) : (
        <FormControlLabel
          control={
            <ToggleButton
              defaultChecked={isChecked}
              onChange={(e) =>
                handleToggle(e, roles.active, row.party_id, roles.subscription_id, roles, roles.id)
              }
              name={roles.name}
              id={roles.id}
              value={roles.id}
            />
          }
          // label={roles.name}
        />
      )}
    </div>
  );
}
