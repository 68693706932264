import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { Face, Fingerprint } from "@material-ui/icons";
import { Paper } from "@material-ui/core";
import { Redirect, useHistory } from "react-router-dom";
import { useStyles } from "../styles/styles";
import SAMSAPIService from "../api/SAMSService";
import AuthManager from "../common/AuthManager";
import * as val from "../common/regex";
import Spinners from "../components/common/spinners";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityIcon from "@material-ui/icons/Visibility";
import IconButton from "@material-ui/core/IconButton";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { checkNetwork } from "../common/regex";

export default function Login() {
  const classes = useStyles();
  let history = useHistory();
  const initialState = {
    email: "",
    password: "",
  };
  const [value, setValue] = React.useState(initialState);
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, seterrorMessage] = React.useState("");

  React.useEffect(() => {
    if (!AuthManager.isAuthenticated()) {
      history.push("/login");
    } else {
      history.push("/dashboard");
    }
    return () => {};
  }, []);

  const changeHandler = (e) => {
    e.preventDefault();
    setValue({ ...value, [e.target.name]: e.target.value });
  };

  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const submitHandler = async (e) => {
    try {
      if (val.isEmpty(value.email)) {
        seterrorMessage("Please Enter your Email ID");
        return;
      } else if (!val.validateEmail(value.email)) {
        seterrorMessage("Please Check your Email ID");
        return;
      } else if (val.isEmpty(value.password)) {
        seterrorMessage("Please Enter your Password");
      } else {
        setLoading(true);
        const ret = await SAMSAPIService.doPost(
          "user/auth",
          { is_admin: true },
          {
            email: val.trimWhiteSpace(value.email),
            password: val.trimWhiteSpace(value.password),
          }
        );
        if (ret) setLoading(false);

        if (!ret.valid) seterrorMessage(!ret.message ? "" : ret.message);
        else if (!ret.au_id) seterrorMessage("Invalid User");
        else {
          AuthManager.setAuthUser(ret.token);
          history.replace("/dashboard");
        }
      }
    } catch (error) {
      setLoading(false);
      seterrorMessage("Oops! something went wrong");
    }
  };

  return (
    <div>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Paper>
          <div className={classes.paper}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              style={{ paddingTop: "50px" }}
            >
              <img
                src={`${process.env.PUBLIC_URL}/assets/logo.png`}
                style={{ width: "50px", height: "50px" }}
              />
            </Grid>

            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            {loading && <Spinners />}
            {errorMessage !== "" && (
              <div className={classes.errorMessage}>
                <br /> {errorMessage}
              </div>
            )}

            <form className={classes.form} noValidate>
              <Grid container spacing={2} alignItems="flex-end">
                <Grid item>
                  <Face className={classes.IconSize} />
                </Grid>
                <Grid item md={true} sm={true} xs={true}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    size="small"
                    fullWidth
                    autoFocus
                    value={value.email}
                    onChange={changeHandler}
                    onKeyDown={(e) => {
                      if (e.key === "Enter")
                        document.getElementById("password").focus();
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2} alignItems="flex-end">
                <Grid item>
                  <Fingerprint className={classes.IconSize} />
                </Grid>
                <Grid item md={true} sm={true} xs={true}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    id="password"
                    autoComplete="current-password"
                    size="small"
                    value={value.password.trim()}
                    onChange={changeHandler}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") submitHandler();
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            style={{ padding: "0px" }}
                          >
                            {showPassword ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              {/* <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
                style={{ marginLeft: "44px" }}
              /> */}
              <Button
                type="button"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={submitHandler}
                disabled={loading ? true : false}
              >
                Sign In
              </Button>

              {/* <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
              </Grid> */}
               <Button
                type="button"
                fullWidth
                variant="text"
                color="primary"
                href={"/forgot_password"}
                style = {{textTransform: "none", margin:0}}
                disableFocusRipple={true}
                disableElevation={true}
                className={classes.submit}
                disabled={loading ? true : false}
              >
                forgot password?
              </Button>
            </form>
            
          </div>
          <Box mt={8}>
            <Typography variant="body2" color="textSecondary" align="center">
              {"Copyright © "}
              {/* <Link color="inherit" href="#"> */}
              Compatio
              {/* </Link>{" "} */}
              <span>&nbsp;&nbsp;</span> {new Date().getFullYear()}
              {"."}
            </Typography>
          </Box>
        </Paper>
      </Container>
    </div>
  );
}
