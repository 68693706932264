import React from "react";
import { Route, Switch } from "react-router-dom";
import Store from "./common/store";
import Layout from "./views/layout";
import Dashboard from "./dashboard";
import LoginForm from "./views/login";
import PrivateRoute from "./route/privateRoute";
import ViewParties from "./views/parties/viewParties";
import ViewPartyType from "./views/partyType/viewPartyType";
import ViewUsers from "./views/users/viewUsers";
import ViewRoles from "./views/roles/viewRoles";
import ViewPermissions from "./views/permissions/viewPermissions";
import ViewModules from "./views/modules/viewModules";
import DBPromotionHome from "./views/dbpromotion/index";
//import DBPromotionAddEditServer from "./views/dbpromotion/addEditServer";
import ViewRP from "./views/rolesPermissions/view";
import AddUser from "./views/users/addUser";
import userParty from "./views/userParty/addUserParty";
import ResetPassword from "./views/resetPassword";
import ForgotPassword from "./views/forgotPassword";
import VerifyEmail from "./views/verifyEmail";
export default function App() {
  const dev_api_url =
    "http://graph-dev-api-1953012399.us-east-2.elb.amazonaws.com";

  const dev = {
    api_url: dev_api_url,
  };
  return (
    <Store>
      <div>
        <Switch>
          <Route path="/" exact={true} component={LoginForm} />
          <Route path="/login" exact={true} component={LoginForm} />
          <Route path="/reset_password/:token" exact component={ResetPassword}/>
          <Route path="/forgot_password" exact component={ForgotPassword}/>
          <Route path="/verify_email/:token" exact component={VerifyEmail}/>
          <PrivateRoute path="/users" exact={true} component={ViewUsers} />
          <PrivateRoute path="/dashboard" exact component={Dashboard} />
          <PrivateRoute path="/parties" exact component={ViewParties} />
          <PrivateRoute path="/partytype" exact component={ViewPartyType} />
          <PrivateRoute path="/roles" exact component={ViewRoles} />
          {/* <PrivateRoute path="/permissions" exact component={ViewPermissions} /> */}
          {/* <PrivateRoute path="/rolespermissons" exact component={ViewRP} /> */}
          <PrivateRoute path="/roles/:id/permissons" exact component={ViewRP} />
          <PrivateRoute path="/modules" exact component={ViewModules} />
          <PrivateRoute path="/users/add" exact component={AddUser} />
          <PrivateRoute path="/users/edit" exact component={AddUser} />
          <PrivateRoute
            path="/users/party/manage"
            exact
            component={userParty}
          />
        </Switch>
      </div>
    </Store>
  );
}
