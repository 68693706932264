import React, { useState, useEffect } from "react";
import { FormControlLabel } from "@material-ui/core";
import ToggleButton from "../common/toggleButton";
import { useMutation } from "@apollo/client";
import { ADD_EDIT_ROLE_PERMISSION } from "../../utils/graphQL/mutations";
import { GET_ROLE_PERMISSIONS } from "../../utils/graphQL/queries";
import Spinners from "../common/spinners";

export default function ToggleButtonComponent({ rowMap, isChecked, roleId }) {
  const [checked, setChecked] = useState(isChecked);
  const [addEditRolePermission, { loading, errors, data }] = useMutation(
    ADD_EDIT_ROLE_PERMISSION,
    {
      errorPolicy: "all",
    }
  );

  const handleToggle = async (e, rowMap) => {
    if (checked) {
      rowMap.is_permissionActive = false;
    } else {
      rowMap.is_permissionActive = true;
    }
    setChecked(rowMap.is_permissionActive);
    const response = await addEditRolePermission({
      variables: {
        role_id: roleId,
        permission_id: rowMap.id,
        is_active: rowMap.is_permissionActive,
      },
      refetchQueries: [
        { query: GET_ROLE_PERMISSIONS, variables: { id: roleId } },
      ],
      awaitRefetchQueries: true,
    });
  };

  return (
    <div>
      {loading ? (
        <Spinners />
      ) : (
        <FormControlLabel
          control={
            <ToggleButton
              defaultChecked={checked}
              onChange={(e) => handleToggle(e, rowMap)}
              name={rowMap.name}
            />
          }
          // label="enabled"
        />
      )}
    </div>
  );
}
