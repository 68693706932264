import React, { useRef, forwardRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { blue } from "@material-ui/core/colors";
import AddRole from "../../components/roles/addRole";

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

function AddEditRole(props, ref) {
  const classes = useStyles();
  const {
    onClose,
    open,
    datas,
    title,
    permissions,
    dataProductList,
    dataPartyList,
    setopenAlert,
    setalertMessage,
    setSeverity,
  } = props;

  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
      <AddRole
        datas={datas}
        ref={ref}
        permissions={permissions}
        onClose={onClose}
        dataPartyList={dataPartyList}
        dataProductList={dataProductList}
        setopenAlert={setopenAlert}
        setalertMessage={setalertMessage}
        setSeverity={setSeverity}
      />
    </Dialog>
  );
}

AddEditRole.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default forwardRef(AddEditRole);
