import React, { useState, createContext } from "react";

export const DelAlertContext = createContext(false);
export const AddAlertContext = createContext(false);
export const DelPermissionAlertContext = createContext(false);
export const AddPermissionAlertContext = createContext(false);
export const RolesPermission = createContext();
// export const AddPartyType = createContext(false);

const Store = ({ children }) => {
  const [delAlertValue, setDelAlertValue] = useState(false);
  const [addAlertValue, setAddAlertValue] = useState(false);
  const [delPermissionAlertValue, setDelPermissionAlertValue] = useState(false);
  const [addPermissionAlertValue, setAddPermissionAlertValue] = useState(false);
  const [rolesPermission, setRolesPermission] = useState();

  return (
    <div>
      <AddAlertContext.Provider value={[addAlertValue, setAddAlertValue]}>
        <DelAlertContext.Provider value={[delAlertValue, setDelAlertValue]}>
          <DelPermissionAlertContext.Provider
            value={[delPermissionAlertValue, setDelPermissionAlertValue]}
          >
            <AddPermissionAlertContext.Provider
              value={[addPermissionAlertValue, setAddPermissionAlertValue]}
            >
              <RolesPermission.Provider
                value={[rolesPermission, setRolesPermission]}
              >
                {children}
              </RolesPermission.Provider>
            </AddPermissionAlertContext.Provider>
          </DelPermissionAlertContext.Provider>
        </DelAlertContext.Provider>
      </AddAlertContext.Provider>
    </div>
  );
};
export default Store;
