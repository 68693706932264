/* eslint-disable no-use-before-define */
import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";

const filterOptions = createFilterOptions({
  matchFrom: "start",
  stringify: (option) => option.title,
});

export default function Filter({ data, activeRole, setactiveRole, label }) {
  const [rD, setrD] = React.useState(data);
  // const [activeRole, setactiveRole] = React.useState();
  React.useEffect(() => {
    // setrD(props.data.roles);
    return () => {};
  }, [rD]);

  const handleChange = (e, value) => {
    if (value == undefined) {
      setactiveRole("");
    } else {
      setactiveRole(value.id);
    }
  };

  return (
    <div>
      {data && (
        <Autocomplete
          id="filter-demo"
          options={data !== null || undefined ? data : "NULL"}
          getOptionLabel={(option) => option.name}
          // filterOptions={filterOptions}
          // style={{ width: 150 }}
          // defaultValue={data.find((v) => v.name[0])}
          onChange={(event, value) => handleChange(event, value)}
          size="small"
          renderInput={(params) => (
            <TextField {...params} label={label} variant="outlined" />
          )}
        />
      )}
    </div>
  );
}
