import React from "react";
// import { Chart } from "react-charts";
import Layout from "./views/layout";
import Image from "./assests/images/compatio.png";
import Grid from "@material-ui/core/Grid";

export default function MyChart() {
  //   const data = React.useMemo(
  //     () => [
  //       {
  //         label: "Series 1",
  //         data: [
  //           [0, 1],
  //           [1, 2],
  //           [2, 4],
  //           [3, 2],
  //           [4, 7],
  //         ],
  //       },
  //       {
  //         label: "Series 2",
  //         data: [
  //           [0, 3],
  //           [1, 1],
  //           [2, 5],
  //           [3, 6],
  //           [4, 4],
  //         ],
  //       },
  //     ],
  //     []
  //   );

  //   const axes = React.useMemo(
  //     () => [
  //       { primary: true, type: "linear", position: "bottom" },
  //       { type: "linear", position: "left" },
  //     ],
  //     []
  //   );

  // A react-chart hyper-responsively and continuously fills the available
  // space of its parent element automatically

  return (
    <Layout>
      {/* <div
        style={{
          width: "400px",
          height: "300px",
        }}
      >
        <Chart data={data} axes={axes} />
      </div> */}
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        style={{ paddingTop: "100px" }}
      >
        <img
          src={`${process.env.PUBLIC_URL}/assets/compatio.png`}
          style={{ width: "50%" }}
          alt="logo"
        />
      </Grid>
    </Layout>
  );
}
