import React from "react";

export function validateEmail(email) {
  if (email.length > 0) {
    const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regexp.test(email);
  }
  return false;
}

export function phonenumber(phone) {
  const regex = /^[0-9]{10}$/;
  if (regex.test(phone)) return true;
}

export function validate_firstName(inputfirstname) {
  var regName = /^[a-zA-Z]+$/;
  if (inputfirstname.length <= 15) {
    if (regName.test(inputfirstname)) return true;
  }
  return false;
}

export function validate_address(inputaddress) {
  // var regName = /^[a-zA-Z]+$/;
  if (inputaddress.length > 0 && inputaddress.length <= 300) {
    return true;
  }
  return false;
}

export function isEmpty(value) {
  return value == null || value == undefined || value.toString().trim() === "";
}

export function isNumeric(num) {
  return !isNaN(num);
}
export function trimWhiteSpace(str) {
  return str.replace(/\s+/g, "");
}
export function hasWhiteSpace(string) {
  // return /\s/g.test(str);
  if (string.length == string.replace(" ", "").length) {
    return false;
  }
  return true;
}

const inputRegex = new Map();
inputRegex.set(
  "email",
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);
inputRegex.set("phonenumber", /^[0-9]{10}$/);
inputRegex.set("name", /^[a-zA-Z]+$/);

export function validate_input(input, UIName, fieldName, length) {
  if (isEmpty(input)) {
    return "Please enter " + UIName + ".";
  }

  if (hasWhiteSpace(input)) {
    if (fieldName !== "address")
      return "White Spaces are not allowed in " + UIName;
  }

  if (input.length >= length) {
    return UIName + " should be less than " + length + " characters";
  }

  if (inputRegex.has(fieldName)) {
    const regex = inputRegex.get(fieldName);
    if (!regex.test(input)) {
      return "Please enter valid data for " + UIName;
    }
  }
  return false;
}

export const checkNetwork = () => {
  let checkNetwork = navigator.onLine;
  if (!checkNetwork) {
    return false;
  }
  return true;
};
