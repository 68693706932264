import React, { useState, useEffect } from "react";
import ToggleButton from "../../common/toggleButton";
import { useMutation } from "@apollo/client";
import { ADD_EDIT_USER_PARTY } from "../../../utils/graphQL/mutations";
// import { GET_ROLE_PERMISSIONS } from "../../utils/graphQL/queries";
import Spinners from "../../common/spinners";

import {
  TextField,
  makeStyles,
  useTheme,
  FormControl,
  Button,
  CssBaseline,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Container,
  Paper,
  Typography,
  Divider,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormGroup,
  Checkbox,
  TableRow,
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableHead,
} from "@material-ui/core";

export default function ToggleButtonComponent({
  allRoles,
  isChecked,
  userRoles,
  userDetails,
  setUserstate,
}) {
  var clone = null;

  const [checked, setChecked] = useState(isChecked);
  const [spin, setspin] = useState(false);
  const [addEditUserParty, { loading, errors, data }] = useMutation(
    ADD_EDIT_USER_PARTY,
    {
      errorPolicy: "all",
    }
  );

  const [roles, setroles] = useState();
  const [array2, setarray2] = useState();
  const [array1, setarray1] = useState([]);
  const [loader, setloader] = useState(false);

  useEffect(() => {
    if (allRoles) {
      clone = JSON.parse(JSON.stringify(allRoles));
      clone.active = true;
      setroles(clone);
      setarray2(clone);
      getAvailableRoles(allRoles, userRoles);
    }
    return () => {};
  }, [userRoles]);

  const handleToggle = async (e, act, upi, ri, clone) => {
    setloader(true);
    setUserstate(false);
    if (act === true) {
      clone.active = false;
      setChecked(false);
      setroles(clone);
      setUserstate(false);
      setspin(true);
      const response = await addEditUserParty({
        variables: {
          user_id: userRoles.user_id,
          party_id: userRoles.party_id,
          user_type_id: userRoles.user_type_id,
          role_id: ri,
        },
      });
      setUserstate(true);
      if (response) setloader(false);
    } else {
      clone.active = true;
      setChecked(true);
      setroles(clone);
      setspin(true);
      setUserstate(false);
      const response = await addEditUserParty({
        variables: {
          user_id: userDetails.user_id,
          party_id: userDetails.party_id,
          user_type_id: userDetails.user_type_id,
          role_id: ri,
        },
        // refetchQueries: [
        //   { query: GET_ROLE_PERMISSIONS, variables: { id: roleId } },
        // ],
        // awaitRefetchQueries: true,
      });
      setUserstate(true);
      if (response) setloader(false);
    }
  };

  useEffect(() => {
    return () => {};
  }, [roles]);

  const getAvailableRoles = (allRoles, userRoles) => {
    const availableRoles = allRoles.filter(function (role) {
      for (let i = 0; i < userRoles.length; i++) {
        if (role.id === userRoles[i].id) {
          return false;
        }
      }
      return true;
    });
    setarray2(availableRoles);
    return availableRoles;
  };

  return (
    <div>
      <TableContainer style={{ padding: "15px" }}>
        <Table size="small" aria-label="a dense table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Role Name</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loader ? (
              <Spinners />
            ) : (
              array2 &&
              array2.map((d, index) => (
                <TableRow>
                  <TableCell component="th" scope="row">
                    {d.name}
                  </TableCell>
                  <TableCell>
                    <FormControlLabel
                      control={
                        <ToggleButton
                          defaultChecked={isChecked}
                          onChange={(e) =>
                            handleToggle(e, d.active, userRoles.id, d.id, d)
                          }
                          name={d.name}
                          id={d.id}
                          value={d.id}
                        />
                      }
                      // label={roles.name}
                    />
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* {!roles ? (
        <Spinners />
      ) : (
        <FormControlLabel
          control={
            <ToggleButton
              defaultChecked={isChecked}
              onChange={(e) =>
                handleToggle(e, roles.active, userRoles.id, roles.id, roles)
              }
              name={roles.name}
              id={roles.id}
              value={roles.id}
            />
          }
          // label={roles.name}
        />
      )} */}
    </div>
  );
}
