class AuthManager {
  __session_storage_key = "Compatio.SAMS.admin.Logges.user.Object";

  static setAuthUser(value) {
    localStorage.setItem(this.__session_storage_key, value);
  }

  static getAuthUser() {
    if (this.isAuthenticated())
      localStorage.getItem(this.__session_storage_key);
  }

  static isAuthenticated() {
    return this.__session_storage_key in localStorage;
  }

  static getToken() {
    if (this.isAuthenticated()) {
      const str = localStorage.getItem(this.__session_storage_key);
      return str;
    }
  }

  static logoutUser() {
    if (this.isAuthenticated())
      localStorage.removeItem(this.__session_storage_key);
    localStorage.clear();
  }
}

export default AuthManager;
