import React, { forwardRef, useState, useContext } from "react";
import { GET_ROLES } from "../../utils/graphQL/queries";
import { DEL_ROLE } from "../../utils/graphQL/mutations";
import { useMutation } from "@apollo/client";
import ConfirmationDialog from "../common/confirmationDialog";
export function DelRole(props, ref) {
  const {
    onClose,
    open,
    title,
    setopenAlert,
    setalertMessage,
    setloader,
    setSeverity,
  } = props;
  const [deleteRole, { loading }] = useMutation(DEL_ROLE);
  const [spinners, setSpinners] = useState(false);
  const [errorMessage, seterrorMessage] = useState(false);

  const handleDelete = async () => {
    var id = ref.current.row ? ref.current.row.id : null;
    const response = await deleteRole({
      variables: { id: id },
      refetchQueries: [{ query: GET_ROLES }],
      awaitRefetchQueries: true,
    });
    if (response.data.deleteRole.deleted === true) {
      seterrorMessage(false);
      setSpinners(false);
      setopenAlert(true);
      setalertMessage("Role has been Deleted");
      setSeverity(true);
    } else {
      seterrorMessage(true);
      setSpinners(false);
    }
    props.onClose();
    setloader(false);
  };
  return (
    <div>
      <ConfirmationDialog
        open={open}
        handleClose={onClose}
        handleSubmit={handleDelete}
        dialogTitle="Are you sure you want to Delete Role ?"
      />
    </div>
  );
}
export default forwardRef(DelRole);
