import React from "react";
import Layout from "../layout";
import { Grid, Button, Fab } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import SAMSAPIService from "../../api/SAMSService";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    display: "inline-block",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

function Index() {
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;
  const history = useHistory();
  var [loading, setLoading] = React.useState(false);
  var [serverList, setServerList] = React.useState([]);
  const [formError, setformError] = React.useState({
    severity: "error",
    message: null,
    error: false,
    display: false,
  });

  //loadServerList();
  function handle_addEdit_Server(id) {
    history.push("/dbpromotion/server/add/" + id);
  }

  async function handle_delete_Server(id) {
    if (window.confirm("Do you really want to delete this record ?")) {
      const ret = await SAMSAPIService.doGet("dbp/server/delete/" + id);
      if (ret.status) {
        setError(null, "Record is deleted succesfully");
        let tempVar = JSON.parse(JSON.stringify(serverList));
        let newlist = tempVar.map((item, index) => {
          if (item.id === id) {
            item.is_deleted = true;
            return item;
          }
          return item;
        });
        setServerList(newlist);
        setTimeout(() => {
          setError(null);
        }, 5000);
      }
    }
  }

  function setError(ErrorMessage = null, successMessage = null) {
    setformError({
      ...formError,
      error: ErrorMessage != null,
      message: successMessage == null ? ErrorMessage : successMessage,
      severity: ErrorMessage != null ? "error" : "success",
      display: ErrorMessage != null || successMessage != null,
    });
    return false;
  }

  async function loadServerList() {
    setLoading(true);
    const ret = await SAMSAPIService.doGet("dbp/server/list");
    //console.log(ret);
    setServerList(ret);
    //console.log(serverList);
  }

  function filteredServers() {
    var filteredServers = serverList.filter((p) => !p.is_deleted);
    return filteredServers;
  }

  React.useEffect(() => {
    loadServerList();
  }, []);
  return (
    <React.Fragment>
      <Layout>
        <Grid container>
          <Grid item>
            <h3>List of Servers</h3>
          </Grid>
          <Grid item style={{ marginLeft: "auto" }}>
            <Button
              variant="outlined"
              color="default"
              size="small"
              style={{ marginRight: "10px" }}
              onClick={() => loadServerList()}
            >
              Refresh
            </Button>
            &nbsp;
            <Button
              variant="outlined"
              color="primary"
              size="small"
              style={{ marginRight: "10px" }}
              onClick={() => handle_addEdit_Server(0)}
            >
              Add New Server
            </Button>
          </Grid>
        </Grid>
        <hr></hr>
        {formError.display && (
          <Alert size="small" severity={formError.severity}>
            {formError.message}
          </Alert>
        )}
        <div className="row">
          {filteredServers().map((item) => (
            <Card className={classes.root} key={item.id} variant="outlined">
              <CardContent>
                <Typography
                  className={classes.title}
                  color="textSecondary"
                  gutterBottom
                >
                  {item.name}
                </Typography>
                <Typography variant="h5" component="h2">
                  {item.server_ip}
                </Typography>
                <Typography className={classes.pos} color="textSecondary">
                  adjective
                </Typography>
                <Typography variant="body2" component="p">
                  well meaning and kindly.
                  <br />
                  {'"a benevolent smile"'}
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  onClick={() => handle_addEdit_Server(item.id)}
                >
                  Edit
                </Button>
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  onClick={() => handle_delete_Server(item.id)}
                >
                  Delete
                </Button>
              </CardActions>
            </Card>
          ))}
        </div>
      </Layout>
    </React.Fragment>
  );
}

export default Index;
