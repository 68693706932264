import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MuiAlert from "@material-ui/lab/Alert";

export default function snackBar({
  message,
  openAlert,
  handleAlert,
  Severity,
}) {
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  return (
    <div>
      <Snackbar
        open={openAlert}
        autoHideDuration={6000}
        onClose={handleAlert}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        key={{ vertical: "bottom", horizontal: "left" }}
      >
        {Severity ? (
          <Alert onClose={handleAlert} severity="error">
            {message}
          </Alert>
        ) : (
          <Alert onClose={handleAlert} severity="success">
            {message}
          </Alert>
        )}
      </Snackbar>
    </div>
  );
}
