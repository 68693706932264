import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import { useQuery } from "@apollo/client";
import { GET_PARTIES } from "../../../utils/graphQL/queries";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const AccordionDrp = (props) => {
  const classes = useStyles();
  const [party, setParty] = React.useState("");
  const { loading, error, data, refetch } = useQuery(GET_PARTIES);

  const handleChangeDrop = (event) => {
    if (event.target.value !== null || undefined) {
      setParty(event.target.value);
      props.setPartySelect(true);
    } else {
      props.setPartySelect(false);
    }
  };

  React.useEffect(() => {
    props.setPartyState(party);
  }, [party]);

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="demo-simple-select-label">List of Parties</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={party}
        onChange={handleChangeDrop}
        disabled={props.disabled}
      >
        {loading && !data ? (
          <span>loading</span>
        ) : (
          data.parties.map((row) => (
            <MenuItem value={row.id}>{row.name}</MenuItem>
          ))
        )}
      </Select>
    </FormControl>
  );
};

export default AccordionDrp;
