import React, {
  forwardRef,
  useState,
  useRef,
  useEffect,
  useContext,
} from "react";
import {
  TextField,
  makeStyles,
  useTheme,
  FormControl,
  Button,
  CssBaseline,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Container,
} from "@material-ui/core";
import { useMutation } from "@apollo/client";
import { addPartyComponent } from "../../styles/styles";
import { ADD_EDIT_PERMISSION } from "../../utils/graphQL/mutations";
import { GET_PERMISSIONS, GET_MODULES } from "../../utils/graphQL/queries";
import Spinners from "../common/spinners";
import ConfirmationDialog from "../common/confirmationDialog";
import * as val from "../../common/regex";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(2),
    },
  },
  formGroup: {
    "& > *": {
      margin: theme.spacing(1),
      //   width: 200
    },
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  submitIcon: {
    margin: theme.spacing(2, 0, 2),
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
    maxWidth: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

function AddPermission(props, ref) {
  const { datas, rowMap } = props;
  var moduleId = ref.current.row ? ref.current.row.id : "";
  var moduleNameVar = ref.current.row ? ref.current.row.name : "";
  const classes = useStyles();
  const theme = useTheme();
  const initialState = {
    partyName: "",
    partyType: "",
    partyEmail: "",
  };
  const [typeName, setTypeName] = useState("");
  const [moduleName, setModuleName] = useState(moduleId);
  const [bol, setBol] = useState();
  const [optionsValue, setoptionsValue] = useState();
  const [results, setResults] = useState(datas.modules);
  const [open, setOpen] = useState(false);
  const [spinners, setSpinners] = useState(false);
  const [addEditPermission, { errors, data }] = useMutation(
    ADD_EDIT_PERMISSION,
    {
      errorPolicy: "all",
    }
  );
  const [errMessage, seterrMessage] = useState("");
  var permissionId = rowMap.current.row ? rowMap.current.row.id : null;
  const [caption, setCaption] = useState("");
  const [dumRef, setdumRef] = useState(ref.current.row);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    // setName({ ...initialState });
    setTypeName("");
  };
  const handleClickClose = (e) => {
    e.preventDefault();
    setTypeName("");
    props.onClose();
  };

  const handleReset = (e) => {
    e.preventDefault();
    // setName({ ...initialState });
  };

  const handleName = (e) => {
    e.preventDefault();
    setTypeName({ ...typeName, [e.target.name]: e.target.value });
    // setModuleName({...moduleName, [e.target.name] : ref.current.row.id})
  };
  const handleModuleName = (e, id) => {
    // e.preventDefault();
    // setTypeName({ ...typeName, [e.target.name]: e.target.value });
    setModuleName(id);
  };

  const handleChange = (e) => {
    setoptionsValue(e.target.value);
  };

  const handleRadioButton = (e) => {
    e.persist();
    if (e.target.value === "yes" || undefined || "") {
      setBol(true);
    } else if (e.target.value === "no") {
      setBol(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSpinners(true);
    const nameValidation = val.validate_input(
      typeName.permissionName,
      "Permission Name",
      "name",
      15
    );
    if (nameValidation) {
      seterrMessage(nameValidation);
      setSpinners(false);
      handleClose();
    } else {
      const response = await addEditPermission({
        variables: {
          name: typeName.permissionName,
          parentid: moduleName,
          is_active: bol,
        },
        refetchQueries: [{ query: GET_MODULES }],
        awaitRefetchQueries: true,
      });

      if (response.errors) {
        seterrMessage(response.errors[0].message);
        setSpinners(false);
        handleClose();
      } else {
        seterrMessage("");
        props.onClose();
        handleClose();
        setSpinners(false);
        props.setopenAlert(true);
        props.setalertMessage("New Permision has been Added");
        props.setSeverity(false);
      }
    }

    ref.current.row = null;
  };
  return (
    <div>
      {spinners && <Spinners />}
      <Container component="main" maxWidth="xs">
        <div style={{ color: "red" }}>{errMessage}</div>
        <CssBaseline />
        <div className={classes.paper}>
          <form className={classes.form} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="moduleName"
                  label="Module Name"
                  name="moduleName"
                  autoComplete="moduleName"
                  defaultValue={moduleNameVar}
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  autoFocus
                  required
                  fullWidth
                  id="permissionName"
                  label="Permission Name"
                  name="permissionName"
                  autoComplete="permissionName"
                  //     defaultValue={rowMap.current.row ? rowMap.current.row.name : ""}
                  value={typeName.permissionName}
                  onChange={handleName}
                />
              </Grid>
              {/* <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">
                  Module Name
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="optionsValue"
                  value={optionsValue}
                  onChange={handleChange}
                  defaultValue={optionsValue}
                >
                  {results.length &&
                    results.map((data) => (
                      <MenuItem value={data.id}>{data.name}</MenuItem>
                    ))}
                </Select>
              </FormControl> */}
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Status</FormLabel>
                  <RadioGroup
                    aria-label="activateAccount"
                    name="activateAccount"
                    id="activateAccount"
                    defaultValue={
                      ref.current.row && ref.current.row.is_active
                        ? "yes"
                        : "yes"
                    }
                    onChange={handleRadioButton}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Enable"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="Disable"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>

            <Grid
              container
              justify="flex-end"
              spacing={2}
              className={classes.submitIcon}
            >
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  style={{ marginRight: "10px" }}
                  className={classes.button}
                  onClick={handleClickClose}
                >
                  Cancel
                </Button>
                {/* <Fab color="primary" aria-label="add" onClick={handleClickOpen}>
                <AddIcon />
              </Fab> */}
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className={classes.button}
                  onClick={handleClickOpen}
                >
                  save
                </Button>
              </Grid>
            </Grid>
            <ConfirmationDialog
              open={open}
              handleClose={handleClose}
              handleSubmit={handleSubmit}
              dialogTitle={"Are you sure you want to Add Permission ?"}
            />
          </form>
        </div>
      </Container>
    </div>
  );
}
export default forwardRef(AddPermission);
