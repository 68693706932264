import React, { forwardRef, useState, useContext } from "react";
import { GET_USERS } from "../../utils/graphQL/queries";
import { DEL_USER } from "../../utils/graphQL/mutations";
import { useMutation } from "@apollo/client";
import ConfirmationDialog from "../common/confirmationDialog";
import { DelAlertContext } from "../../common/store";

export function DelUser(props, ref) {
  const {
    onClose,
    open,
    title,
    setopenAlert,
    setalertMessage,
    setloader,
    setSeverity,
  } = props;

  const [deleteUser, { loading }] = useMutation(DEL_USER);
  const [spinners, setSpinners] = useState(false);
  const [errorMessage, seterrorMessage] = useState(false);
  const handleDelete = async () => {
    setloader(true);
    var id = ref.current.row ? ref.current.row.id : null;
    const response = await deleteUser({
      variables: { id: id },
      refetchQueries: [{ query: GET_USERS }],
      awaitRefetchQueries: true,
    });
    if (response.data.deleteUser.deleted === true) {
      seterrorMessage(false);
      setopenAlert(true);
      setalertMessage("User has been Deleted");
      setSeverity(true);
    } else {
      seterrorMessage(true);
      setSpinners(false);
    }
    props.onClose();
    setloader(false);
  };

  return (
    <div>
      <ConfirmationDialog
        open={open}
        handleClose={onClose}
        handleSubmit={handleDelete}
        dialogTitle="Are you sure you want to Delete User ?"
      />
    </div>
  );
}

export default forwardRef(DelUser);
