import React from "react";

import { useTable, useFilters, useSortBy, usePagination } from "react-table";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TablePagination from "@material-ui/core/TablePagination";
import ImportExportIcon from "@material-ui/icons/ImportExport";

import Paper from "@material-ui/core/Paper";

import compareIgnoreCase from "./tableDataCompare";
import TablePaginationActions from "./tablePaginationActions";

function TableComponent({ tableHeaders, tableData }) {
  // React.useEffect(() => {
  //   return () => {};
  // }, [tableData]);
  const useStyles = makeStyles({
    table: {
      minWidth: 650,
      // minHeight: 450,
    },
    visuallyHidden: {
      border: 1,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    sortArrow: {
      border: 1,
      clip: "rect(0 0 0 0)",
      // height: 1,
      margin: -1,
      // overflow: "hidden",
      paddingTop: 5,
      // position: "absolute",
      // top: 20,
      fontSize: "22px",
      width: 30,
    },
    root: {
      flexShrink: 0,
      // marginLeft: theme.spacing(2.5),
    },
  });

  const classes = useStyles();
  function AutoColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
      const options = new Set();
      preFilteredRows.forEach((row) => {
        options.add(row.values[id]);
      });
      return [...options.values()];
    }, [id, preFilteredRows]);

    return (
      <Autocomplete
        id="table-search"
        options={options !== null || undefined ? options : ""}
        getOptionLabel={(option) =>
          option !== null || undefined ? option.toString() : ""
        }
        onChange={(event, value) => {
          setFilter(value || undefined);
        }}
        size="small"
        renderInput={(params) => (
          <TextField {...params} label="Search" variant="outlined" />
        )}
      />
    );
  }
  const columns = React.useMemo(() => tableHeaders, []);

  const data = React.useMemo(() => tableData, []);

  const defaultColumn = React.useMemo(
    () => ({
      Filter: AutoColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    visibleColumns,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      sortTypes: {
        alphanumeric: (row1, row2, columnName) => {
          return compareIgnoreCase(
            row1.values[columnName],
            row2.values[columnName]
          );
        },
        initialState: { pageIndex: 2 },
      },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  return (
    <>
      <TableContainer className={classes.table} component={Paper}>
        <Table
          size="small"
          aria-label="a dense table"
          stickyHeader
          {...getTableProps()}
        >
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <>
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <>
                      <TableCell
                        width={column.width}
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {column.render("Header")}
                        {column.canSort && !column.isSorted ? (
                          <ImportExportIcon className={classes.sortArrow} />
                        ) : null}
                        <TableSortLabel
                          active={column.isSorted}
                          direction={column.isSortedDesc ? "desc" : "asc"}
                        >
                          {column.isSorted ? (
                            <span className={classes.visuallyHidden}>
                              {column.isSortedDesc
                                ? "sorted descending"
                                : "sorted ascending"}
                            </span>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                    </>
                  ))}
                </TableRow>
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <TableCell {...column.getHeaderProps()}>
                      {column.canFilter ? column.render("Filter") : null}
                    </TableCell>
                  ))}
                </TableRow>
              </>
            ))}
          </TableHead>
          <TableBody>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <TableCell {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 75, 100]}
        colSpan={3}
        count={rows.length}
        rowsPerPage={pageSize}
        page={pageIndex}
        onChangePage={(event, page) => gotoPage(page)}
        onChangeRowsPerPage={(event) => setPageSize(Number(event.target.value))}
        ActionsComponent={TablePaginationActions}
        style={{ display: "flex", flexDirection: "column", padding: "0px" }}
      />
    </>
  );
}

export default TableComponent;
