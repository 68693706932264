const API_ROOT_URL = process.env.REACT_APP_API_URL;

const doPost = async function (url, inputdata, authdata = null, tok = null) {
  url = API_ROOT_URL + url;
  let data = null;
  try {
    const rawResponse = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization:
          authdata != null
            ? "Basic " +
              Buffer.from(authdata.email + ":" + authdata.password).toString(
                "base64"
              )
            : null,
      },
      body: JSON.stringify(inputdata),
    });
    data = rawResponse.json();
  } catch (error) {
    return { error: error };
  } finally {
  }
  return data;
};

const doEmail = async function (url, inputdata, tok = null) {
  url = API_ROOT_URL + url;
  let data = null;
  try {
    const rawResponse = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: tok != null ? `Bearer ${tok}` : null,
      },
      body: JSON.stringify(inputdata),
    });
    data = rawResponse.json();
  } catch (error) {
    return { error: error };
  } finally {
  }
  return data;
};

const doGet = async function (url, inputdata, authdata = null) {
  url = API_ROOT_URL + url;
  let data = null;
  try {
    const rawResponse = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization:
          authdata != null
            ? "Basic " +
              Buffer.from(authdata.email + ":" + authdata.password).toString(
                "base64"
              )
            : null,
      },
      body: JSON.stringify(inputdata),
    });
    data = rawResponse.json();
  } catch (error) {
    return { error: error };
  } finally {
  }
  return data;
};

export default {
  doPost: doPost,
  doGet: doGet,
  doEmail: doEmail,
  url: API_ROOT_URL,
};
