import React from "react";
import {
  TextField,
  makeStyles,
  useTheme,
  FormControl,
  Button,
  CssBaseline,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Container,
  Paper,
  Typography,
  Divider,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItem,
} from "@material-ui/core";
// import { makeStyles, Button } from "@material-ui/core";
import PointPanel from "./components/dynamicAccordion";
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  button: {
    marginBottom: "10px",
  },
}));
const TransportFlow = ({ user }) => {
  const classes = useStyles();
  const [points, setPoints] = React.useState([]);
  const [panelName, setPanelName] = React.useState(0);
  const addPoint = (e) => {
    e.preventDefault();
    let arr = [];
    arr.push("dummy"); // setPoints([...arr]);
    setPoints([...points, arr]);
    setPanelName(panelName + 1);
  };
  return (
    <div className="flow">
      <Button
        variant="contained"
        color="primary"
        size="small"
        className={classes.button} // startIcon={<SaveIcon />} // component={Link} // to={"/users/party/manage"} // onClick={() => manageUserParty(row)}
        onClick={addPoint}
      >
        Associate New Party
      </Button>
            
      {points.map((point) => (
        <PointPanel panelName={panelName} user={user} />
      ))}
          
    </div>
  );
};
export default TransportFlow;
