import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import { Button } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MailIcon from "@material-ui/icons/Mail";
import StorageIcon from "@material-ui/icons/Storage";
import MenuIcon from "@material-ui/icons/Menu";
import HomeIcon from "@material-ui/icons/Home";
import VisibilityIcon from "@material-ui/icons/Visibility";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import RemoveShoppingCartIcon from "@material-ui/icons/RemoveShoppingCart";
import DesktopAccessDisabledIcon from "@material-ui/icons/DesktopAccessDisabled";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import SearchIcon from "@material-ui/icons/Search";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import {
  Switch,
  Route,
  Link,
  BrowserRouter,
  Redirect,
  useHistory,
} from "react-router-dom";
import { ListItemSecondaryAction } from "@material-ui/core";
import AddParty from "./parties/addParty";
import AddPartyType from "./partyType";
import ViewParty from "./parties/viewParties";
import ViewPartyType from "./partyType/viewPartyType";
import { layoutStyle } from "../styles/styles";
import AuthManager from "../common/AuthManager";

function MainLayout(props) {
  const { container } = props;
  const classes = layoutStyle();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isAuthenticated, setisAuthenticated] = React.useState(true);
  let history = useHistory();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const logoutHandler = () => {
    AuthManager.logoutUser();
    setisAuthenticated(false);
    history.replace("/login");
  };

  const drawer = (
    <div>
      <div className={classes.toolbar}>
        <div style={{ textAlign: "center", paddingTop: "20px" }}>
          <h1>DASHBOARD</h1>

          {/* <div className={classes.wrapperBg}></div> */}
        </div>
      </div>
      <Divider />
      {/* <List>
        {["Inbox", "Starred"].map((text, index) => (
          <ListItem key={text} component={Link} to={"/" + text}>
            <ListItemIcon>
              {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      
      </List> */}

      <List>
        <ListItem button component={Link} to={"/dashboard"}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem button component={Link} to={"/users"}>
          <ListItemIcon>
            <VisibilityIcon />
          </ListItemIcon>
          <ListItemText primary="Users" />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem button component={Link} to={"/parties"}>
          <ListItemIcon>
            <PeopleOutlineIcon />
          </ListItemIcon>
          <ListItemText primary="Parties" />
        </ListItem>

        <ListItem button component={Link} to={"/partytype"}>
          <ListItemIcon>
            <PermIdentityIcon />
          </ListItemIcon>
          <ListItemText primary="Party Type" />
        </ListItem>
        <Divider />
        <ListItem button component={Link} to={"/roles"}>
          <ListItemIcon>
            <PermIdentityIcon />
          </ListItemIcon>
          <ListItemText primary="Roles" />
        </ListItem>
        {/* <ListItem button component={Link} to={"/permissions"}>
          <ListItemIcon>
            <DesktopAccessDisabledIcon />
          </ListItemIcon>
          <ListItemText primary="Permissions" />
        </ListItem> */}
        <Divider />
        <ListItem button component={Link} to={"/modules"}>
          <ListItemIcon>
            <MailIcon />
          </ListItemIcon>
          <ListItemText primary="Modules" />
        </ListItem>
        <Divider />
        {/* <ListItem button component={Link} to={"/dbpromotion"}>
          <ListItemIcon>
            <StorageIcon />
          </ListItemIcon>
          <ListItemText primary="DB Promotion" />
        </ListItem> */}
      </List>
    </div>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />

      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            SAMS - Admin Panel
          </Typography>
          <Button
            color="inherit"
            style={{ marginLeft: "auto" }}
            onClick={logoutHandler}
          >
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>

      <main className={classes.content}>
        <div className={classes.toolbar} />
        {/* <Typography paragraph>Admin Page</Typography> */}
        {props.children}
      </main>
    </div>
  );
}

MainLayout.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  container: PropTypes.instanceOf(
    typeof Element === "undefined" ? Object : Element
  ),
};

export default MainLayout;
