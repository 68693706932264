import React, {
  forwardRef,
  useState,
  useRef,
  useEffect,
  useContext,
} from "react";
import {
  TextField,
  makeStyles,
  useTheme,
  FormControl,
  Button,
  CssBaseline,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Container,
  Paper,
  Typography,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router-dom";
import Fab from "@material-ui/core/Fab";
import { useMutation } from "@apollo/client";
import { AddAlertContext } from "../../common/store";
import { addPartyComponent } from "../../styles/styles";
import { ADD_EDIT_USER } from "../../utils/graphQL/mutations";
import { GET_USERS } from "../../utils/graphQL/queries";
import Spinners from "../common/spinners";
import ConfirmationDialog from "../common/confirmationDialog";
import passwordGen from "../../common/randomPassword";
import * as val from "../../common/regex";
import SAMSAPIService from "../../api/SAMSService";
import AuthManager from "../../common/AuthManager";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(2),
    },
  },
  formGroup: {
    "& > *": {
      margin: theme.spacing(1),
      //   width: 200
    },
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "24px",
    paddingLeft: "50px",
    paddingRight: "50px",
    width: "50%",
    margin: "auto",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  submitIcon: {
    margin: theme.spacing(2, 0, 2),
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
    maxWidth: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  number: {
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  input: {
    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
}));

function AddEditUser({ userEditData, title }) {
  let history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const initialState = {
    firstName: "",
    lastName: "",
    email: "",
    contactNo: "",
    address: "",
  };
  const [typeName, setTypeName] = useState(initialState);
  const [bol, setBol] = useState();
  const [optionsValue, setoptionsValue] = useState();
  const [open, setOpen] = useState(false);
  const [spinners, setSpinners] = useState(false);
  const [addEditUser, { errors, data }] = useMutation(ADD_EDIT_USER, {
    errorPolicy: "all",
  });
  const [errMessage, seterrMessage] = useState("");
  const [addAlertValue, setAddAlertValue] = useContext(AddAlertContext);
  const [successMessage, setsuccessMessage] = useState("");
  const [autoPassword, setAutoPassword] = useState();
  const [emailId, setemailId] = useState();

  useEffect(() => {
    const randomPassword = passwordGen();
    setAutoPassword(randomPassword);
  }, []);

  const handleClickOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const handleClickClose = (e) => e.preventDefault();

  const handleName = (e) => {
    e.preventDefault();
    if (e.target.name === "contactNo" && isNaN(e.target.value)) {
      seterrMessage("Please enter valid mobile number");
    }
    setTypeName({ ...typeName, [e.target.name]: e.target.value });
  };

  const handleChange = (e) => setoptionsValue(e.target.value);

  const handleRadioButton = (e) => {
    e.persist();
    if (e.target.value === "yes" || undefined || "") {
      setBol(true);
    } else if (e.target.value === "no") {
      setBol(false);
    }
  };

  const clearForm = (params) => {
    setTypeName(initialState);
    seterrMessage("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSpinners(true);
    setsuccessMessage("");
    seterrMessage("");
    if (!val.validate_firstName(typeName.firstName)) {
      seterrMessage("Please Enter Valid First Name within 15 characters");
      setSpinners(false);
      handleClose();
    } else if (!val.validate_firstName(typeName.lastName)) {
      seterrMessage("Please Enter Valid Last Name within 15 characters");
      setSpinners(false);
      handleClose();
    } else if (!val.validateEmail(typeName.email)) {
      seterrMessage("Please fill valid email address");
      setSpinners(false);
      handleClose();
    } else if (!val.phonenumber(typeName.contactNo)) {
      seterrMessage("Please enter valid mobile number");
      setSpinners(false);
      handleClose();
    } else if (!val.validate_address(typeName.address)) {
      seterrMessage("Please enter valid address");
      setSpinners(false);
      handleClose();
    } else {
      seterrMessage("");
      const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var token_generated = '';
      for (var i = 0; i < 32; i++) {
          var randomPoz = Math.floor(Math.random() * charset.length);
          token_generated += charset.substring(randomPoz,randomPoz+1);
      }
      const response = await addEditUser({
        variables: {
          first_name: typeName.firstName,
          last_name: typeName.lastName,
          email: typeName.email,
          mobile: typeName.contactNo,
          password: autoPassword,
          address: typeName.address,
          token:token_generated,
          is_active: bol === undefined || null || "" ? true : bol,
        },
        refetchQueries: [{ query: GET_USERS }],
        awaitRefetchQueries: true,
      });
      if (response.errors) {
        seterrMessage(response.errors[0].message);
        setSpinners(false);
        seterrMessage(response.errors[0].message);
        handleClose();
      } else {
        seterrMessage("");
        setAddAlertValue(true);
        handleClose();
        setSpinners(false);
        if (response.data.addEditUser) {
          welcomeMail(response.data.addEditUser.email);
        }
        setTypeName(initialState);
        setsuccessMessage(
          "Successfully Created New User ! Please check inbox to reset password"
        );
      }
    }
  };

  const welcomeMail = async (emailid) => {
    var tok = AuthManager.getToken();
    const res = await SAMSAPIService.doEmail(
      "user/password/generate",
      { email: emailid, sams: true },
      tok
    );
  };

  return (
    <div>
      <Container component="main">
        <CssBaseline />
        <Paper className={classes.paper} elevation={5}>
          <Typography component="h1" variant="h5">
            {title ? title : "Create New User"}
          </Typography>
          <div style={{ color: "red", marginTop: "10px" }}>{errMessage}</div>
          <div style={{ color: "green", marginTop: "10px" }}>
            {successMessage}
          </div>
          {spinners && <Spinners />}
          <form className={classes.form} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  name="firstName"
                  autoComplete="firstName"
                  value={typeName.firstName}
                  onChange={handleName}
                  autoFocus={true}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="lastName"
                  value={typeName.lastName}
                  onChange={handleName}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  value={typeName.email}
                  onChange={handleName}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="password"
                  label={autoPassword}
                  name="password"
                  autoComplete="password"
                  value={typeName.password}
                  onChange={handleName}
                  disabled={true}
                />

                <h6 style={{ margin: "0 2px auto" }}>
                  Auto Generated Password
                </h6>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  className={classes.number}
                  variant="outlined"
                  required
                  fullWidth
                  id="contactNo"
                  label="Contact No"
                  name="contactNo"
                  value={typeName.contactNo}
                  onChange={handleName}
                  inputProps={{ maxLength: 10 }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="address"
                  label="Address"
                  name="address"
                  value={typeName.address}
                  onChange={handleName}
                  multiline
                  rows={4}
                  rowsMax={6}
                />
              </Grid>

              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Status</FormLabel>
                  <RadioGroup
                    aria-label="activateAccount"
                    name="activateAccount"
                    id="activateAccount"
                    defaultValue="yes"
                    onChange={handleRadioButton}
                    style={{ flexDirection: "row" }}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Enable"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="Disable"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              container
              justify="space-between"
              spacing={2}
              // className={classes.submitIcon}
            >
              <Grid item justify="space-between" alignItems="flex-start">
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  style={{ marginRight: "10px" }}
                  className={classes.button}
                  onClick={clearForm}
                >
                  Reset
                </Button>
              </Grid>

              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  style={{ marginRight: "10px" }}
                  className={classes.button}
                  onClick={() => {
                    history.push("/users");
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className={classes.button}
                  onClick={handleClickOpen}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
            <ConfirmationDialog
              open={open}
              handleClose={handleClose}
              handleSubmit={handleSubmit}
              dialogTitle="Are you sure you want to Add User ?"
            />
          </form>
        </Paper>
      </Container>
    </div>
  );
}
export default AddEditUser;
