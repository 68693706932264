import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { blue } from "@material-ui/core/colors";
import AddParty from "../../components/parties/addParty";

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

function Party(props, ref) {
  const classes = useStyles();
  const {
    onClose,
    open,
    datas,
    title,
    setopenAlert,
    setalertMessage,
    setSeverity,
  } = props;
  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
      <AddParty
        datas={datas}
        ref={ref}
        onClose={onClose}
        setopenAlert={setopenAlert}
        setalertMessage={setalertMessage}
        setSeverity={setSeverity}
      />
    </Dialog>
  );
}

Party.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default forwardRef(Party);
