import React, { forwardRef, useState, useRef, useEffect, useContext } from 'react';
import {
	TextField,
	makeStyles,
	useTheme,
	FormControl,
	Button,
	CssBaseline,
	FormControlLabel,
	FormLabel,
	Grid,
	Radio,
	RadioGroup,
	Container,
} from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { useMutation } from '@apollo/client';
import { ADD_EDIT_PARTY } from '../../utils/graphQL/mutations';
import { GET_PARTIES } from '../../utils/graphQL/queries';
import Spinners from '../common/spinners';
import ConfirmationDialog from '../common/confirmationDialog';
import * as val from '../../common/regex';
import * as validation from '../../common/validations';

const useStyles = makeStyles(theme => ({
	root: {
		'& > *': {
			margin: theme.spacing(2),
		},
	},
	formGroup: {
		'& > *': {
			margin: theme.spacing(1),
			//   width: 200
		},
	},
	paper: {
		marginTop: theme.spacing(2),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	submitIcon: {
		margin: theme.spacing(2, 0, 2),
	},

	formControl: {
		margin: theme.spacing(1),
		minWidth: 200,
		maxWidth: 300,
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
	chips: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	chip: {
		margin: 2,
	},
	noLabel: {
		marginTop: theme.spacing(3),
	},
}));

function AddParty(props, ref) {
	const { datas } = props;
	const classes = useStyles();
	const theme = useTheme();
	const initialState = {
		partyName: '',
		partyType: '',
		partyEmail: '',
	};
	const [typeName, setTypeName] = useState('');
	const [optionsValue, setoptionsValue] = useState();
	const [results, setResults] = useState(datas.party_types);
	const [open, setOpen] = useState(false);
	const [spinners, setSpinners] = useState(false);
	const [addEditParty, { errors, data }] = useMutation(ADD_EDIT_PARTY, {
		errorPolicy: 'all',
	});
	const [errMessage, seterrMessage] = useState('');
	var id = ref.current.row ? ref.current.row.id : null;
	const [caption, setCaption] = useState('');
	var radioStatus = ref.current.row ? ref.current.row.is_active : true;
	const [isActive, setIsActive] = useState(`${radioStatus}`);
	const [bol, setBol] = useState(radioStatus);
	const [updateButton, setupdateButton] = useState(true);
	let [refState, setRefState] = useState(ref.current.row);

	useEffect(() => {
		if (id === null) {
			setCaption('Save');
		} else {
			setCaption('update');
		}
	}, []);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		// setName({ ...initialState });
	};
	const handleClickClose = e => {
		e.preventDefault();
		props.onClose();
	};

	const handleReset = e => {
		e.preventDefault();
		// setName({ ...initialState });
	};

	const handleName = e => {
		e.preventDefault();
		setTypeName({ ...typeName, [e.target.name]: e.target.value });
	};

	const handleChange = e => {
		setoptionsValue(e.target.value);
	};

	const handleRadioButton = e => {
		e.persist();
		if (e.target.value === 'true' || undefined || '') {
			setBol(true);
		} else if (e.target.value === 'false') {
			setBol(false);
		}
	};

	const submitter = async (name, stat, optionsValue, address) => {
		const response = await addEditParty({
			variables: {
				id: id,
				name: name,
				type_id: optionsValue,
				is_active: bol,
				address: address,
			},
			refetchQueries: [{ query: GET_PARTIES }],
			awaitRefetchQueries: true,
		});
		if (response.errors) {
			seterrMessage(response.errors[0].message);
			setSpinners(false);
			handleClose();
		} else {
			seterrMessage('');
			props.onClose();
			handleClose();
			setSpinners(false);
			props.setopenAlert(true);
			props.setalertMessage('New Party has been Added');
			props.setSeverity(false);
		}

		return;
	};

	const handleSubmit = async e => {
		e.preventDefault();
		setSpinners(true);
		seterrMessage('');
		var id = ref.current.row ? ref.current.row.id : null;
		if (caption === 'Save') {
			const nameValidation = val.validate_input(typeName.partyName, 'Party Name', 'name', 50);
			const addressValidation = val.validate_input(typeName.partyHQAddress, 'Party Address', 'address', 300);
			const partyTypeValidation = val.isNumeric(optionsValue);
			if (nameValidation) {
				seterrMessage(nameValidation);
				setSpinners(false);
				handleClose();
			} else if (addressValidation) {
				seterrMessage(addressValidation);
				setSpinners(false);
				handleClose();
			} else if (!partyTypeValidation) {
				seterrMessage('Please Choose Party Type');
				setSpinners(false);
				handleClose();
			} else {
				submitter(typeName.partyName, bol, optionsValue, typeName.partyHQAddress);
			}
		} else if (caption === 'update') {
			const validateName = val.validate_input(document.getElementById('partyName').value, 'Party Name', 'name', 50);
			const nameValidation = val.validate_input(typeName.partyName, 'Party Name', 'name', 50);
			const address_Validation = val.validate_input(
				document.getElementById('partyHQAddress').value,
				'Party Address',
				'address',
				300
			);
			const addressValidation = val.validate_input(typeName.partyHQAddress, 'Party Address', 'address', 300);
			const partyTypeValidation = val.isNumeric(optionsValue);

			if (validateName) {
				seterrMessage(validateName);
				setSpinners(false);
				handleClose();
			} else if (address_Validation) {
				seterrMessage(address_Validation);
				setSpinners(false);
				handleClose();
			} else {
				const response = await addEditParty({
					variables: {
						id: refState.id,
						name: typeName.partyName ? typeName.partyName : refState.name,
						type_id: optionsValue ? optionsValue : refState.type_id,
						is_active: bol,
						address: typeName.partyHQAddress ? typeName.partyHQAddress : refState.address,
					},
					refetchQueries: [{ query: GET_PARTIES }],
					awaitRefetchQueries: true,
				});
				if (response.errors) {
					seterrMessage(response.errors[0].message);
					setSpinners(false);
					handleClose();
				} else {
					seterrMessage('');
					props.onClose();
					handleClose();
					setSpinners(false);
					props.setopenAlert(true);
					props.setalertMessage('Party Type has been Updated');
					props.setSeverity(false);
				}
			}
		}
		ref.current.row = null;
	};

	return (
		<div>
			{spinners && <Spinners />}
			<Container component="main" maxWidth="xs">
				<div style={{ color: 'red' }}>{errMessage}</div>
				<CssBaseline />
				<div className={classes.paper}>
					<form className={classes.form} noValidate>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<TextField
									variant="outlined"
									autoFocus
									required
									fullWidth
									id="partyName"
									label="Party Name"
									name="partyName"
									autoComplete="partyName"
									defaultValue={ref.current.row ? ref.current.row.name : ''}
									// value={typeName.partyName}
									onChange={handleName}
								/>
							</Grid>

							<Grid item xs={12}>
								<TextField
									variant="outlined"
									required
									fullWidth
									id="partyHQAddress"
									label="Party Address"
									name="partyHQAddress"
									multiline
									rows={2}
									rowsMax={4}
									defaultValue={ref.current.row ? ref.current.row.address : ''}
									value={typeName.partyHQAddress}
									onChange={handleName}
								/>
							</Grid>
							<Grid item xs={12}>
								<FormControl className={classes.formControl}>
									<InputLabel id="demo-simple-select-label">Party Type</InputLabel>
									<Select
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										name="optionsValue"
										value={optionsValue}
										onChange={handleChange}
										defaultValue={ref.current.row ? ref.current.row.type_id : null}
									>
										{results.length &&
											results.map(data => data.is_active && <MenuItem value={data.id}>{data.name}</MenuItem>)}
									</Select>
								</FormControl>
							</Grid>

							<Grid item xs={12}>
								<FormControl component="fieldset">
									<FormLabel component="legend">Status</FormLabel>
									<RadioGroup
										aria-label="activateAccount"
										name="activateAccount"
										id="activateAccount"
										defaultValue={isActive}
										onChange={handleRadioButton}
									>
										<FormControlLabel value="true" control={<Radio />} label="Enable" />
										<FormControlLabel value="false" control={<Radio />} label="Disable" />
									</RadioGroup>
								</FormControl>
							</Grid>
						</Grid>

						<Grid container justify="flex-end" spacing={2} className={classes.submitIcon}>
							<Grid item>
								<Button
									variant="contained"
									color="secondary"
									size="small"
									style={{ marginRight: '10px' }}
									className={classes.button}
									onClick={handleClickClose}
								>
									Cancel
								</Button>

								<Button
									variant="contained"
									color="primary"
									size="small"
									className={classes.button}
									onClick={handleClickOpen}
									disabled={!updateButton}
								>
									{caption}
								</Button>
							</Grid>
						</Grid>
						<ConfirmationDialog
							open={open}
							handleClose={handleClose}
							handleSubmit={handleSubmit}
							dialogTitle={id ? 'Are you sure you want to update Party ?' : 'Are you sure you want to Add Party ?'}
						/>
					</form>
				</div>
			</Container>
		</div>
	);
}
export default forwardRef(AddParty);
