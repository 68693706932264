import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(10),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(5),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "90%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  IconSize: {
    height: "40px",
    width: "40px",
  },
  errorMessage: {
    color: "red",
  },
}));

export const drawerWidth = 240;
export const layoutStyle = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    marginLeft: drawerWidth,
    //backgroundImage: `url(${BlueBackground})`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    backgroundColor: "#5167dd",
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    // backgroundImage: `url(${BlueBackground})`,
    // color: "White"
    // backgroundColor: "#3f51b5"
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    //backgroundImage: `url(${BlueBackground})`,
    backgroundSize: "cover",
    height: "100vh",
  },
  table1: {
    width: "auto",
    height: "200px",
  },

  wrapperBg: {
    //backgroundImage: `url(${logo})`
    // height: "100vh"
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
}));

export const addPartyTypeComponent = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(2),
    },
  },
  checked: {},
  formGroup: {
    "& > *": {
      margin: theme.spacing(1),
      //   width: 200
    },
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  submitIcon: {
    margin: theme.spacing(2, 0, 2),
  },
}));

export const addPartyComponent = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(2),
    },
  },
  formGroup: {
    "& > *": {
      margin: theme.spacing(1),
      //   width: 200
    },
  },
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  submitIcon: {
    margin: theme.spacing(2, 0, 2),
  },
}));

export const tableStatus = makeStyles((theme) => ({
  display: {
    display: "flex",
    justifyContent: "flex-end",
  },
  active: {
    color: "green",
  },
  inactive: {
    color: "red",
  },
  nullValue: {
    color: "grey",
  },
}));


export const resetPassword = makeStyles((theme) => ({

}))