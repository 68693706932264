import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Layout from "../layout";
import AddEditUser from "../../components/users/addUser";
import EditUser from "../../components/users/editUser";

export default function AddUser() {
  const location = useLocation();

  React.useEffect(() => {}, [location]);

  var data = location.state ? location.state.data.row : null;
  var title = location.state ? location.state.data.title : null;
  return (
    <div>
      <Layout>
        {data !== null ? (
          <EditUser userEditData={data} title={title} />
        ) : (
          <AddEditUser />
        )}
      </Layout>
    </div>
  );
}
