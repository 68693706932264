import React, { forwardRef, useState, useEffect } from "react";
import _ from "lodash";
import Layout from "../layout";
import {
  makeStyles,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  Paper,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Button,
} from "@material-ui/core";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import {
  GET_MODULES,
  GET_PERMISSIONS,
  GET_ROLE_PERMISSIONS,
  GET_ROLES,
} from "../../utils/graphQL/queries";
import Spinners from "../../components/common/spinners";
import ToggleButtonComponent from "../../components/rolePermissions/toggleButtonComponent";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  cardWidth: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  table: {
    //  maxWidth: 275,
    height: "250px",
    // height:"auto",
    width: "auto",
  },
}));

function View(props) {
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;
  const roleIdString = props.match.params.id;
  const roleId = parseInt(roleIdString);
  const [rolePerm, setRolePerm] = useState();
  // const {
  //   loading: rolePermissionLoading,
  //   error: rolePermissionError,
  //   data: rolePermissionData,
  // } = useQuery(GET_ROLE_PERMISSIONS, {
  //   variables: { id: roleId },
  // });

  const [
    getRolePermissions,
    {
      loading: rolePermissionLoading,
      error: rolePermissionError,
      data: rolePermissionData,
    },
  ] = useLazyQuery(GET_ROLE_PERMISSIONS, {
    variables: { id: roleId },
  });

  const [
    getModules,
    { loading: moduleLoading, error: moduleError, data: moduleData },
  ] = useLazyQuery(GET_MODULES);

  const [modulesApollo, setModulesApollo] = useState([]);
  const [rolePermApollo, setRolePermApollo] = useState([]);
  const [roleName, setRoleName] = useState();
  const [checked, setChecked] = React.useState(false);
  const [modRow, setModRow] = React.useState([]);
  const [permRow, setPermRow] = React.useState([]);
  const [filterResult, setFilterResult] = React.useState([]);
  const loading = moduleLoading || rolePermissionLoading;
  const error = moduleError || rolePermissionError;

  useEffect(() => {
    getModules();
    getRolePermissions();
    return () => {};
  }, []);

  useEffect(() => {
    if (moduleData && rolePermissionData) {
      handleModuleRow();
      handlePermRow();
    }

    return () => {};
  }, [moduleData, rolePermissionData]);

  useEffect(() => {
    if (modRow && permRow) {
      processData();
    }
    return () => {};
  }, [modRow, permRow]);

  const handleModuleRow = async () => {
    moduleData &&
      moduleData.modules.length &&
      moduleData.modules.map((moduleRow) => {
        moduleRow.permissions.map((permMap) => {
          setModRow((modRow) => [
            ...modRow,
            {
              id: permMap.id,
              name: permMap.name,
              is_active: permMap.is_active,
            },
          ]);
        });
      });
  };

  const handlePermRow = async () => {
    rolePermissionData &&
      rolePermissionData.rolepermissions.length &&
      setRoleName(rolePermissionData.rolepermissions);
    rolePermissionData.rolepermissions.map((rolePermRow) => {
      rolePermRow.permissions.map((roleMapPerm) => {
        setPermRow((permRow) => [
          ...permRow,
          {
            id: roleMapPerm.id,
            name: roleMapPerm.name,
            is_active: roleMapPerm.is_active,
          },
        ]);
      });
    });
  };

  const processData = async () => {
    const handleSubmitFunction = await filterFunction();
    if (moduleData) {
      let tempVar = JSON.parse(JSON.stringify(moduleData));
      var moduleValuesResult = tempVar.modules.map((moduleRow) => {
        moduleRow.permissions.map((permmMap) => {
          handleSubmitFunction.map((filteredItem) => {
            // permmMap.is_permissionActive = false

            if (permmMap.id == filteredItem.id) {
              permmMap.is_permissionActive = filteredItem.is_active;
            }
          });
          return permmMap;
        });
        return moduleRow;
      });
      setModulesApollo(moduleValuesResult);
    }
  };

  const filterFunction = async () => {
    let result = await modRow.filter((o1) =>
      permRow.some((o2) => o1.id === o2.id)
    );
    setFilterResult(result);

    return result;
  };

  if (error) return <div>error...</div>;

  return (
    <Layout>
      {moduleLoading || rolePermissionLoading ? (
        <Spinners />
      ) : (
        <div className={classes.root}>
          <Grid container spacing={3} style={{ border: "1px solid black" }}>
            <Grid
              item
              xs={12}
              style={{ backgroundColor: "#5167dd", color: "white" }}
            >
              Role Name :{" "}
              {rolePermissionData &&
                rolePermissionData.rolepermissions &&
                rolePermissionData.rolepermissions[0].name}
            </Grid>
            <Grid item xs={12}>
              List of Modules
            </Grid>

            {modulesApollo.length &&
              modulesApollo.map((row) => (
                <Grid item xs>
                  <Card className={classes.cardWidth}>
                    <CardHeader subheader={row.name} />
                    <CardContent>
                      <TableContainer
                        className={classes.table}
                        component={Paper}
                      >
                        <Table
                          size="small"
                          aria-label="a dense table"
                          stickyHeader
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell>Permission Name</TableCell>
                              <TableCell>Status</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {!row.permissions.length ? (
                              <span
                                style={{
                                  marginLeft: "15px",
                                  marginTop: "15px",
                                }}
                              >
                                Record not available
                              </span>
                            ) : (
                              row.permissions.map((rowMap) =>
                                rowMap.is_active ? (
                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      {rowMap.name.length > 0
                                        ? rowMap.name
                                        : null}
                                    </TableCell>
                                    <TableCell>
                                      <ToggleButtonComponent
                                        rowMap={rowMap}
                                        isChecked={rowMap.is_permissionActive}
                                        roleId={roleId}
                                      />
                                    </TableCell>
                                  </TableRow>
                                ) : null
                              )
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
          </Grid>
        </div>
      )}
      {error && <span>Oops Something went wrong ! Try again Later</span>}
    </Layout>
  );
}

export default forwardRef(View);
