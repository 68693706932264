import React, { useEffect } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { Button, Paper } from "@material-ui/core";
import Spinners from "../components/common/spinners";
import { useStyles } from "../styles/styles";
import {useParams} from 'react-router-dom'
import ResetPasswordForm from "../components/resetPassword/resetPasswordForm";
import SAMSAPIService from "../api/SAMSService";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function VerifyEmail(props) {
    const { token } = useParams() // fetch token for reset password
    const urlParams = new URLSearchParams(props.location.search)
    const redirect = urlParams.get('redirect') // true if its SAMS button will activate
    const rtoUrl = urlParams.get('rtoUrl') // redirect to url
    const party = urlParams.get('party') // redirect to url
    const history = useHistory()

    const classes = useStyles();
    const [loading, setLoading] = React.useState(false);
    const [emailVerified, setEmailVerified] = React.useState(false)

    const verifyEmail = async () => {
        const result = await SAMSAPIService.doGet(
            `user/email/verify/${token}`
        );

        if(result.error === true) setEmailVerified(false)
        else setEmailVerified(true)
        setLoading(false)
    }

    useEffect(() => {
        setLoading(true)
        verifyEmail()
    }, [token])

    useEffect(() => {
        if(emailVerified) window.location.replace(rtoUrl ?? "/login");
    }, [emailVerified])
   
    return (
        <div>
        <Container component="main" maxWidth="xs" className="d-flex align-items-center">
            <CssBaseline />
            <Paper>
            <div className={classes.paper}>
                <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                style={{ paddingTop: "45px" }}
                >
                     <Typography component="h1" variant="h4" ><strong>{party ?? "Compatio"}</strong></Typography><br/>
                    {/* <img
                        src={`${process.env.PUBLIC_URL}/assets/logo.png`}
                        style={{ width: "50px", height: "50px" }}
                    /> */}
                </Grid>
                {
                    loading && 
                    <Grid>
                        <p>Verifying your email</p>
                        <Spinners />
                    </Grid>
                }

                {
                    !emailVerified && !loading &&
                    <Container>
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                            style={{ paddingTop:"20px" }}
                            >
                            <Typography component="h1" variant="h6" align="center">
                                Email verification failed
                            </Typography>
                        </Grid>
                    </Container>
                }

                {
                    emailVerified && (
                    <Container>
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                            style={{ paddingTop:"20px" }}
                            >
                            <Typography component="h1" variant="h6" align="center">
                                Email verification successful
                            </Typography>
                            <br/>
                            <Typography  component="h1">
                                Redirecting to {party ?? "Compatio"} login page
                            </Typography>
                        </Grid>
                        {/* { 
                            (redirect?.toLowerCase() === "true" || rtoUrl !=null) && 
                            <Button
                                type="button"
                                fullWidth
                                variant="contained"
                                color="primary"
                                href = {rtoUrl ?? "/login"}
                                className={classes.submit}
                                // onClick={submitHandler}
                                disabled={loading}
                            >
                                Return to sign in
                            </Button>
                        } */}
                    </Container>)
                }
                
            </div>
            <Box mt={8}>
                <Typography variant="body2" color="textSecondary" align="center">
                {"Copyright © "}
                Compatio
                <span>&nbsp;&nbsp;</span> {new Date().getFullYear()}
                {"."}
                </Typography>
            </Box>
            </Paper>
        </Container>
        </div>
    );
}
