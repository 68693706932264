import React, { useState, useEffect, useRef, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Grid, Button, Fab } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
// import SimpleDialog from "./addPartyType";
import Layout from "../layout";
import EditIcon from "@material-ui/icons/Edit";
import { useQuery, useMutation, NetworkStatus } from "@apollo/client";
import { GET_MODULES, GET_PERMISSIONS } from "../../utils/graphQL/queries";
import { DEL_PERMISSION } from "../../utils/graphQL/mutations";
import Spinners from "../../components/common/spinners";
// import { DEL_PARTY_TYPE } from "../../utils/graphQL/mutations";
import DeleteIcon from "@material-ui/icons/Delete";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import { tableStatus } from "../../styles/styles";
import RefreshIcon from "@material-ui/icons/Refresh";
import AddModule from "./addModule";
import DeleteModule from "../../components/modules/delModule";
import AlertForm from "../../components/common/alert";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddPermission from "../permissions/addPermission";
import EditPermission from "../permissions/editPermission";
import DeletePermission from "../../components/permissions/delPermission";
import AlertNotify from "../../components/common/snackBar";
import SearchBar from "../../components/roles/components/searchBar";
import Utils from "../../common/Utils";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    height: "auto",
  },
  IconSize: {
    height: "20px",
    width: "35px",
    marginRight: "10px",
    cursor: "pointer",
  },
  Acc: {
    marginRight: "0px",
    margin: "auto",
    fontSize: "0.75rem",
    // marginTop: "5px"
  },
  permissionButton: {
    marginLeft: "15px",
  },
  button: {
    textTransform: "none",
  },
});

export default function ViewModules() {
  const classes = useStyles();
  const tableStatusColor = tableStatus();
  const moduleRef = useRef({});
  const delRef = useRef({});
  const permissionRef = useRef({});
  const rowMapPermissionRef = useRef({});
  const delPermissionRef = useRef({});
  const [permDialogOpen, setPermDialogOpen] = React.useState(false);
  const [permEditDialogOpen, setPermEditDialogOpen] = React.useState(false);
  const [permDelopen, setPermDelOpen] = React.useState(false);
  const [moduleDialogOpen, setModuleDialogOpen] = React.useState(false);
  const [moduleDelopen, setModuleDelOpen] = React.useState(false);
  const [dopen, setdOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [queryData, setqueryData] = useState({});
  const [activeRole, setactiveRole] = useState("");

  const {
    loading: queryLoading,
    error,
    data: dataModules,
    networkStatus,
    refetch,
  } = useQuery(GET_MODULES, {
    variables: {
      id: moduleRef.current.row ? moduleRef.current.row.id : null,
    },
    notifyOnNetworkStatusChange: true,
  });
  const [openAlert, setopenAlert] = useState(false);
  const [alertMessage, setalertMessage] = useState();
  const [loader, setloader] = useState(false);
  const [Severity, setSeverity] = useState(false);
  // const { loading: permLoading, error: permError, data: dataPerm } = useQuery(
  //   GET_PERMISSIONS
  // );
  // const [deleteUser, { loading: removeLoading }] = useMutation(DEL_USER);

  let datas = { dataModules };

  // let dataPermissions = { dataPerm };

  const loading = queryLoading;

  console.log(datas);
  // console.log(dataPermissions);
  React.useEffect(() => {
    refetch(GET_MODULES);
    return () => {};
  }, [alertMessage]);

  const handleClickModuleOpen = (row) => {
    moduleRef.current.row = row;
    var titleSetter = moduleRef.current.row ? moduleRef.current.row.id : null;
    if (titleSetter !== null) {
      setTitle("Edit Module");
    } else {
      setTitle("Add Module");
    }
    setModuleDialogOpen(true);
  };

  const handleClickModuleDelete = (e, row) => {
    e.preventDefault();
    delRef.current.row = row;
    console.log(delRef.current.row);
    setModuleDelOpen(true);
  };

  // const handleModuleClose = () => {
  //   setOpen(false);
  //   setModuleDelOpen(false);

  // };

  const handleClickPermissionrOpen = (row, rowMap) => {
    rowMapPermissionRef.current.row = rowMap;
    permissionRef.current.row = row;
    var titleSetter = rowMapPermissionRef.current.row
      ? rowMapPermissionRef.current.row.id
      : null;
    if (titleSetter !== null) {
      setTitle("Edit Permission");
    } else {
      setTitle("Add Permission");
    }
    // setTitle("Add Permission");
    setPermEditDialogOpen(true);
    console.log(rowMapPermissionRef.current.row, "adsf");
    console.log(rowMap, "asdf");
  };

  const handleClickPermissionOpen = (row) => {
    permissionRef.current.row = row;
    // var titleSetter = permissionRef.current.row
    //   ? permissionRef.current.row.id
    //   : null;
    // if (titleSetter !== null) {
    //   setTitle("Edit Permission");
    // } else {
    //   setTitle("Add Permission");
    // }
    setTitle("Add Permission");
    setPermDialogOpen(true);
    console.log(permissionRef.current.row, "row");
    console.log(row, "row");
  };
  const handleClickPermissionDelete = (e, row) => {
    e.preventDefault();
    delPermissionRef.current.row = row;
    console.log(delPermissionRef.current);
    setPermDelOpen(true);
  };

  const handleClose = () => {
    // setOpen(false);
    // setdOpen(false);
    setModuleDialogOpen(false);
    setPermDialogOpen(false);
    setPermEditDialogOpen(false);
  };

  const handleDelClose = () => {
    // setdOpen(false);
    setModuleDelOpen(false);
    setPermDelOpen(false);
  };

  const handleRefresh = async () => {
    try {
      const res = await refetch();
      if (res.queryLoading === true) {
        console.log(res.queryLoading);
      } else if (res.datas.dataModules.modules.length > 0) {
        console.log(
          res.datas.dataModules.modules.length,
          res.datas.dataModules.modules
        );
        return (datas = res.datas.dataModules.modules);
      } else {
        console.log("not working");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAlert = (params) => {
    setopenAlert((openAlert) => !openAlert);
  };

  const handleModuleRow = (row) => {
    moduleRef.current.row = row;
    console.log(moduleRef.current.row);
  };

  let moduleData = null;
  let tableHeaders = null;
  let tableData = null;
  if (!loading && datas.dataModules) {
    if (!Utils.isEmpty(activeRole)) {
      moduleData = datas.dataModules.modules.filter((row) => {
        return row.id == activeRole;
      });
    } else {
      moduleData = datas.dataModules.modules;
    }
  }

  if (error) return console.log(error), (<p>Error :(</p>);

  return (
    <Layout>
      <div>
        <AlertNotify
          openAlert={openAlert}
          handleAlert={handleAlert}
          message={alertMessage}
          Severity={Severity}
        />
        <Grid container>
          <Grid item xs={1} style={{ marginTop: "inherit" }}>
            <h3>Search</h3>
          </Grid>
          <Grid item xs={4} sm={4} style={{ marginLeft: "0px" }}>
            <SearchBar
              data={datas.dataModules && datas.dataModules.modules}
              activeRole={activeRole}
              setactiveRole={setactiveRole}
              label="Search Modules"
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <h3>List of Modules</h3>
          </Grid>
          <Grid item style={{ marginLeft: "auto" }}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ marginRight: "10px", textTransform: "none" }}
              startIcon={<RefreshIcon />}
              onClick={handleRefresh}
            >
              Refresh
            </Button>

            <Button
              variant="contained"
              color="primary"
              size="small"
              className={classes.button}
              startIcon={<SaveIcon />}
              onClick={() => handleClickModuleOpen(null)}
            >
              Add Module
            </Button>
          </Grid>

          <div style={{ width: "100%" }}>
            {loader && <Spinners />}
            {networkStatus === NetworkStatus.refetch ? (
              <Spinners />
            ) : loading && !datas ? (
              <Spinners />
            ) : (
              moduleData &&
              moduleData.map((row) => (
                <Accordion>
                  <Grid item xs={12}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      //   onChange = {handleModuleRow(row)}
                    >
                      <Typography style={{ marginRight: "10px" }}>
                        {row.is_active ? (
                          <RadioButtonCheckedIcon
                            style={{ height: "15px" }}
                            className={tableStatusColor.active}
                          />
                        ) : (
                          <RadioButtonCheckedIcon
                            style={{ height: "15px" }}
                            className={tableStatusColor.inactive}
                          />
                        )}
                      </Typography>
                      <Typography className={classes.heading}>
                        {row.name ? row.name : null}{" "}
                      </Typography>

                      <EditIcon
                        className={classes.IconSize}
                        color="primary"
                        style={{ margin: "auto", marginRight: "0px" }}
                        onClick={() => handleClickModuleOpen(row)}
                      />

                      <DeleteIcon
                        className={classes.IconSize}
                        color="secondary"
                        onClick={(e) => handleClickModuleDelete(e, row)}
                      />
                    </AccordionSummary>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      className={classes.permissionButton}
                      startIcon={<SaveIcon />}
                      onClick={() => handleClickPermissionOpen(row)}
                    >
                      Add Permission
                    </Button>

                    <AccordionDetails>
                      <TableContainer
                        className={classes.table}
                        component={Paper}
                      >
                        <Table
                          size="small"
                          aria-label="a dense table"
                          stickyHeader
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell>Name</TableCell>
                              <TableCell>Status</TableCell>
                              <TableCell>Actions</TableCell>
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            {row.permissions.map((rowMap) => {
                              return (
                                <TableRow>
                                  <TableCell component="th" scope="row">
                                    {rowMap.name ? rowMap.name : null}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    {rowMap.is_active ? (
                                      <RadioButtonCheckedIcon
                                        className={tableStatusColor.active}
                                      />
                                    ) : (
                                      <RadioButtonCheckedIcon
                                        className={tableStatusColor.inactive}
                                      />
                                    )}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    <EditIcon
                                      color="primary"
                                      aria-label="edit"
                                      className={classes.IconSize}
                                      onClick={() =>
                                        handleClickPermissionrOpen(row, rowMap)
                                      }
                                    />

                                    <DeleteIcon
                                      color="secondary"
                                      aria-label="add"
                                      className={classes.IconSize}
                                      onClick={(e) =>
                                        handleClickPermissionDelete(e, rowMap)
                                      }
                                    />
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </AccordionDetails>
                  </Grid>
                </Accordion>
              ))
            )}
          </div>
        </Grid>
        <DeleteModule
          open={moduleDelopen}
          onClose={handleDelClose}
          ref={delRef}
          setopenAlert={setopenAlert}
          setalertMessage={setalertMessage}
          setloader={setloader}
          setSeverity={setSeverity}
        />

        <AddModule
          open={moduleDialogOpen}
          onClose={handleClose}
          datas={datas}
          ref={moduleRef}
          title={title}
          setopenAlert={setopenAlert}
          setalertMessage={setalertMessage}
          setSeverity={setSeverity}
        />
        <DeletePermission
          open={permDelopen}
          onClose={handleDelClose}
          ref={delPermissionRef}
          setopenAlert={setopenAlert}
          setalertMessage={setalertMessage}
          setloader={setloader}
          setSeverity={setSeverity}
        />

        <AddPermission
          open={permDialogOpen}
          onClose={handleClose}
          datas={dataModules}
          ref={permissionRef}
          title={title}
          rowMap={rowMapPermissionRef}
          setopenAlert={setopenAlert}
          setalertMessage={setalertMessage}
          setSeverity={setSeverity}
        />
        <EditPermission
          open={permEditDialogOpen}
          onClose={handleClose}
          datas={dataModules}
          ref={permissionRef}
          title={title}
          rowMap={rowMapPermissionRef}
          setopenAlert={setopenAlert}
          setalertMessage={setalertMessage}
          setSeverity={setSeverity}
        />
      </div>
      <br />
      <div className={tableStatusColor.display}>
        <RadioButtonCheckedIcon className={tableStatusColor.active} />
        Active
        <RadioButtonCheckedIcon className={tableStatusColor.inactive} />
        Not-Active
        <RadioButtonCheckedIcon className={tableStatusColor.nullValue} />
        Not Available
      </div>
    </Layout>
  );
}
