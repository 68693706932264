import React, { forwardRef, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { blue } from "@material-ui/core/colors";
import AddModuleComponent from "../../components/modules/addModule";

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

export function AddModule(props, ref) {
  const classes = useStyles();
  const {
    onClose,
    selectedValue,
    open,
    title,
    datas,
    setopenAlert,
    setalertMessage,
    setSeverity,
  } = props;
  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
      <AddModuleComponent
        ref={ref}
        datas={datas}
        onClose={onClose}
        setopenAlert={setopenAlert}
        setalertMessage={setalertMessage}
        setSeverity={setSeverity}
      />
    </Dialog>
  );
}

AddModule.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default forwardRef(AddModule);
