import React, { forwardRef, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import PersonIcon from "@material-ui/icons/Person";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import { blue } from "@material-ui/core/colors";
import AddPermissionComponent from "../../components/permissions/addPermission";
import EditPermissionComponent from "../../components/permissions/editPermission";

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

export function EditPermission(props, ref) {
  const classes = useStyles();
  const {
    onClose,
    selectedValue,
    open,
    title,
    datas,
    rowMap,
    rows,
    setopenAlert,
    setalertMessage,
    setSeverity,
  } = props;

  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
      <EditPermissionComponent
        ref={ref}
        rowMap={rowMap}
        datas={datas}
        onClose={onClose}
        setopenAlert={setopenAlert}
        setalertMessage={setalertMessage}
        setSeverity={setSeverity}
      />
    </Dialog>
  );
}

EditPermission.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default forwardRef(EditPermission);
