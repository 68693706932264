import React, { forwardRef, useState, useRef, useEffect, useContext } from 'react';
import {
	TextField,
	makeStyles,
	useTheme,
	FormControl,
	Button,
	CssBaseline,
	FormControlLabel,
	FormLabel,
	Grid,
	Radio,
	RadioGroup,
	Container,
	InputLabel,
	Select,
	Input,
	MenuItem,
	FormHelperText,
} from '@material-ui/core';
import { useMutation, useQuery } from '@apollo/client';
import { addPartyComponent } from '../../styles/styles';
import { ADD_EDIT_ROLE } from '../../utils/graphQL/mutations';
import { GET_ROLES, GET_PARTIES, GET_PRODUCTS } from '../../utils/graphQL/queries';
import Spinners from '../common/spinners';
import ConfirmationDialog from '../common/confirmationDialog';
import * as val from '../../common/regex';

const useStyles = makeStyles(theme => ({
	root: {
		'& > *': {
			margin: theme.spacing(2),
		},
	},
	formGroup: {
		'& > *': {
			margin: theme.spacing(1),
			//   width: 200
		},
	},
	paper: {
		marginTop: theme.spacing(2),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	submitIcon: {
		margin: theme.spacing(2, 0, 2),
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 200,
		maxWidth: 300,
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
	chips: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	chip: {
		margin: 2,
	},
	noLabel: {
		marginTop: theme.spacing(3),
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightRegular,
	},
}));
function AddEditRoles(props, ref) {
	const { datas, permissions, dataPartyList, dataProductList } = props;
	const classes = useStyles();
	const theme = useTheme();
	const initialState = {
		partyName: '',
		partyType: '',
		partyEmail: '',
	};
	const [typeName, setTypeName] = useState('');
	// const [bol, setBol] = useState();
	const [productValue, setproductValue] = useState();
	const [open, setOpen] = useState(false);
	const [spinners, setSpinners] = useState(false);
	const [dumRef, setdumRef] = useState(ref.current.row);

	// const {
	//   data: dataPartyList,
	//   error: errorPartyList,
	//   loading: loadingPartyList,
	// } = useQuery(GET_PARTIES);

	// const {
	//   data: dataProductList,
	//   error: errorProductList,
	//   loading: loadingProductList,
	// } = useQuery(GET_PRODUCTS);

	// const [results, setResults] = useState(permissions.permissions);
	const [addEditRole, { errors, data }] = useMutation(ADD_EDIT_ROLE, {
		errorPolicy: 'all',
	});

	const [errMessage, seterrMessage] = useState('');
	var id = ref.current.row ? ref.current.row.id : null;
	var radioStatus = ref.current.row ? ref.current.row.is_active : true;
	const [caption, setCaption] = useState('');
	const [isActive, setIsActive] = useState(`${radioStatus}`);
	const [bol, setBol] = useState(radioStatus);
	const [updateButton, setupdateButton] = useState(true);
	const [Test, setTest] = useState();

	useEffect(() => {
		if (id === null) {
			setCaption('save');
		} else {
			setCaption('update');
		}
	}, []);

	useEffect(() => {}, [dataPartyList]);

	const handleClickOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
		// setTypeName("");
		// setName({ ...initialState });
	};
	const handleClickClose = e => {
		e.preventDefault();
		props.onClose();
		// setTypeName("");
	};
	const handleName = e => {
		e.preventDefault();
		setTypeName({ ...typeName, [e.target.name]: e.target.value });
	};
	const handleRadioButton = e => {
		e.persist();
		if (e.target.value === 'true' || undefined || '') {
			setBol(true);
		} else if (e.target.value === 'false') {
			setBol(false);
		}
	};


	const handleProductChange = event => {
		setproductValue(event.target.value);
	};

	const submitter = async (name, stat, product) => {
		const response = await addEditRole({
			variables: {
				name: name,
				is_active: stat,
				product_id: product,
			},
			refetchQueries: [{ query: GET_ROLES }],
			awaitRefetchQueries: true,
		});
		if (response.errors) {
			seterrMessage(response.errors[0].message);
			setSpinners(false);
			handleClose();
		} else {
			seterrMessage('');
			props.onClose();
			handleClose();
			setSpinners(false);
			props.setopenAlert(true);
			props.setalertMessage('New Role has been Added');
			props.setSeverity(false);
		}
		return;
	};

	const handleSubmit = async e => {
		e.preventDefault();
		var id = ref.current.row ? ref.current.row.id : null;

		setSpinners(true);
		if (caption === 'save') {
			const nameValidation = val.validate_input(typeName.roleName, 'Role Name', 'name', 50);
			const productNameValidation = val.isNumeric(productValue);
			if (nameValidation) {
				seterrMessage(nameValidation);
				setSpinners(false);
				handleClose();
			} 
			
			 else if (!productNameValidation) {
				seterrMessage('Please Choose Product Name');
				setSpinners(false);
				handleClose();
			} else {
				submitter(typeName.roleName, bol, productValue);
			}
		} else if (caption === 'update') {
			const validateName = val.validate_input(document.getElementById('roleName').value, 'Role Name', 'name', 50);
			const nameValidation = val.validate_input(typeName.roleName, 'Role Name', 'name', 50);
			if (validateName) {
				seterrMessage(validateName);
				setSpinners(false);
				handleClose();
			} else {
				const response = await addEditRole({
					variables: {
						id: dumRef.id,
						name: typeName.roleName ? typeName.roleName : dumRef.name,
						is_active: bol,
						product_id: productValue ? productValue : parseInt(dumRef.product_id),
					},
					refetchQueries: [{ query: GET_ROLES }],
					awaitRefetchQueries: true,
				});
				if (response.errors) {
					if (response.errors[0].message === 'No') {
						seterrMessage('');
						props.onClose();
						handleClose();
						setSpinners(false);
						props.setopenAlert(true);
						props.setalertMessage('Role has been Updated');
						props.setSeverity(false);
					} else {
						seterrMessage(response.errors[0].message);
						setSpinners(false);
						handleClose();
					}
				} else {
					seterrMessage('');
					props.onClose();
					handleClose();
					setSpinners(false);
					props.setopenAlert(true);
					props.setalertMessage('Role has been Updated');
					props.setSeverity(false);
				}
			}
		}

		ref.current.row = null;
	};

	return (
		<div>
			{spinners && <Spinners />}
			<Container component="main" maxWidth="xs">
				<div style={{ color: 'red' }}>{errMessage}</div>
				<CssBaseline />
				<div className={classes.paper}>
					<form className={classes.form} noValidate>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<TextField
									variant="outlined"
									autoFocus
									required
									fullWidth
									id="roleName"
									label="Role Name"
									name="roleName"
									autoComplete="roleName"
									value={typeName.roleName}
									onChange={handleName}
									defaultValue={ref.current.row ? ref.current.row.name : null}
								/>
							</Grid>
							{/* {errorPartyList && errorPartyList}
              {loadingPartyList ? (
                "Loading"
              ) : ( */}

							{/* testing */}
							{/* )}
              {errorProductList && errorProductList}
              {loadingProductList ? (
                "Loading"
              ) : ( */}
							<Grid item xs={12}>
								<FormControl className={classes.formControl}>
									<InputLabel id="demo-simple-select-label">Associate Product</InputLabel>
									<Select
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										name="productList"
										value={productValue}
										onChange={handleProductChange}
										defaultValue={ref.current.row ? ref.current.row.product_id : null}
									>
										{dataProductList.product.map(dataProduct => (
											<MenuItem value={dataProduct.id}>{dataProduct.name}</MenuItem>
										))}
									</Select>
								</FormControl>
								<FormHelperText>Associating Product to this role</FormHelperText>
							</Grid>
							{/* )} */}

							<Grid item xs={12}>
								<FormControl component="fieldset">
									<FormLabel component="legend">Status</FormLabel>
									<RadioGroup
										aria-label="activateAccount"
										name="activateAccount"
										id="activateAccount"
										defaultValue={isActive}
										onChange={handleRadioButton}
									>
										<FormControlLabel value="true" control={<Radio />} label="Enable" />
										<FormControlLabel value="false" control={<Radio />} label="Disable" />
									</RadioGroup>
								</FormControl>
							</Grid>
						</Grid>
						<Grid container justify="flex-end" spacing={2} className={classes.submitIcon}>
							<Grid item>
								<Button
									variant="contained"
									color="secondary"
									size="small"
									style={{ marginRight: '10px' }}
									className={classes.button}
									onClick={handleClickClose}
								>
									Cancel
								</Button>
								<Button
									variant="contained"
									color="primary"
									size="small"
									className={classes.button}
									onClick={handleClickOpen}
									disabled={!updateButton}
								>
									{caption}
								</Button>
							</Grid>
						</Grid>
						<ConfirmationDialog
							open={open}
							handleClose={handleClose}
							handleSubmit={handleSubmit}
							dialogTitle={id ? 'Are you sure you want to update Role ?' : 'Are you sure you want to Add Role ?'}
						/>
					</form>
				</div>
			</Container>
		</div>
	);
}
export default forwardRef(AddEditRoles);
