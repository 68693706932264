import React from "react";
import { makeStyles, Paper, Typography, Grid, Avatar, Divider } from "@material-ui/core";
import { green, pink } from "@material-ui/core/colors";
import FolderIcon from "@material-ui/icons/Folder";
import PageviewIcon from "@material-ui/icons/Pageview";
import AssignmentIcon from "@material-ui/icons/Assignment";
import Layout from "./layout";
import AddPartyForm from "../components/parties/addParty";


const useStyles = makeStyles((theme) => ({
  paperstyle: {
    display: "flex",
    "& > *": {
      margin: "auto",
      marginTop: theme.spacing(4),
      // paddingRight:theme.spacing(4),
      // width: theme.spacing(75),
      // height: theme.spacing(16),
    },
  },
  paperHeading: {
    marginLeft: "10px",
    marginTop: "25px",
    marginRight: "50px",
  },
  green: {
    color: "#fff",
    backgroundColor: green[500],
    marginTop: "20px",
    marginLeft: "50px",
    height: "30px",
    width: "30px",
  },
}));

export default function PartyType() {
  const classes = useStyles();

  return (
    <div className={classes.paperstyle}>
      <div>
        <Paper elevation={15} rounded>
          <Grid container direction="row" alignItems="center">
            <Grid item>
              <Avatar className={classes.green}>
                <AssignmentIcon fontSize="medium" />
              </Avatar>
            </Grid>
            <Grid item>
              <Typography
                className={classes.paperHeading}
                variant="h5"
                color="Primary"
              >
                Add Type of Party
              </Typography>
            </Grid>
          </Grid>
          <Divider variant="middle" style={{ marginTop: "5px" }} />
          <AddPartyForm />
          {/* <GetData /> */}
        </Paper>
      </div>
    </div>
  );
}
