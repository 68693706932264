import React, { useState, useEffect, useRef, useContext } from "react";
import { makeStyles, Grid, Button } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import EditIcon from "@material-ui/icons/Edit";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import RefreshIcon from "@material-ui/icons/Refresh";
import { tableStatus } from "../../styles/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import DeletePartyType from "../../components/partyType/delPartyType";
import { GET_PARTY_TYPE } from "../../utils/graphQL/queries";
import { useQuery, useMutation, NetworkStatus } from "@apollo/client";
import SimpleDialog from "./addPartyType";
import Layout from "../layout";
import AlertNotify from "../../components/common/snackBar";
import TableComponent from "../../components/common/table/table";
import Spinners from "../../components/common/spinners";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    height: "500px",
  },
  IconSize: {
    height: "20px",
    width: "35px",
    marginRight: "10px",
    cursor: "pointer",
  },
  button: {
    textTransform: "none",
  },
});

export default function ViewPartyType() {
  const classes = useStyles();
  const tableStatusColor = tableStatus();
  const partyTypeRef = useRef({});
  const delRef = useRef({});
  const [open, setOpen] = useState(false);
  const [dopen, setdOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [Severity, setSeverity] = useState(false);
  const [openAlert, setopenAlert] = useState(false);
  const [alertMessage, setalertMessage] = useState();
  const [loader, setloader] = useState(false);

  const {
    loading: queryLoading,
    error,
    data,
    refetch,
    networkStatus,
  } = useQuery(GET_PARTY_TYPE, {
    notifyOnNetworkStatusChange: true,
  });

  let datas = { data };
  const loading = queryLoading;

  useEffect(() => {
    refetch(GET_PARTY_TYPE);
    return () => {};
  }, [alertMessage]);

  const handleClickOpen = (row) => {
    partyTypeRef.current.row = row;
    var titleSetter = partyTypeRef.current.row
      ? partyTypeRef.current.row.id
      : null;
    if (titleSetter !== null) {
      setTitle("Edit Party Type");
    } else {
      setTitle("Add Party Type");
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelClose = () => {
    setdOpen(false);
  };

  const handleClickDelete = (row) => {
    delRef.current.row = row;
    console.log(row);
    setdOpen(true);
  };

  const handleAlert = (params) => {
    setopenAlert((openAlert) => !openAlert);
  };

  const handleRefresh = async () => {
    try {
      const res = await refetch();
      if (res.loading === true) {
        console.log(res.loading);
      } else if (res.data.party_types !== undefined) {
        console.log(res.data.party_types);
        return (datas = res.data.party_types);
      }

      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  const tableHeaders = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Status",
      accessor: "is_active",
      Cell: ({ cell }) =>
        cell.row.values.is_active ? (
          <RadioButtonCheckedIcon className={tableStatusColor.active} />
        ) : (
          <RadioButtonCheckedIcon className={tableStatusColor.inactive} />
        ),
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: "Actions",
      accessor: "actions",
      Cell: ({ cell }) => (
        <div class="aux">
          <EditIcon
            color="primary"
            aria-label="edit"
            className={classes.IconSize}
            onClick={() => handleClickOpen(cell.row.original)}
          />
          <DeleteIcon
            color="secondary"
            aria-label="add"
            className={classes.IconSize}
            onClick={() => handleClickDelete(cell.row.original)}
          />
        </div>
      ),
      disableFilters: true,
      disableSortBy: true,
    },
  ];

  let tableData = null;

  if (!queryLoading && datas.data) {
    tableData = datas.data.party_types;
  }

  if (error) return console.log(error), (<p>Error :(</p>);

  return (
    <Layout>
      <div>
        <AlertNotify
          openAlert={openAlert}
          handleAlert={handleAlert}
          message={alertMessage}
          Severity={Severity}
          // setSeverity={setSeverity}
        />
        <Grid container>
          <Grid item>
            <h3>List of Party Types</h3>
          </Grid>
          <Grid item style={{ marginLeft: "auto" }}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ marginRight: "10px", textTransform: "none" }}
              startIcon={<RefreshIcon />}
              onClick={handleRefresh}
            >
              Refresh
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              className={classes.button}
              startIcon={<SaveIcon />}
              onClick={() => handleClickOpen(null)}
            >
              Add Party Type
            </Button>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs>
            {loader && <Spinners />}
            {networkStatus === NetworkStatus.refetch ? (
              <Spinners />
            ) : queryLoading && !datas.data ? (
              <Spinners />
            ) : (
              <TableComponent
                tableHeaders={tableHeaders}
                tableData={tableData}
              />
            )}
          </Grid>
        </Grid>

        <SimpleDialog
          open={open}
          onClose={handleClose}
          ref={partyTypeRef}
          title={title}
          setopenAlert={setopenAlert}
          setalertMessage={setalertMessage}
          setSeverity={setSeverity}
        />
        <DeletePartyType
          open={dopen}
          onClose={handleDelClose}
          title="Delete a Party Type ?"
          ref={delRef}
          setopenAlert={setopenAlert}
          setalertMessage={setalertMessage}
          setloader={setloader}
          setSeverity={setSeverity}
        />
      </div>
      <br />
      <div className={tableStatusColor.display}>
        <RadioButtonCheckedIcon className={tableStatusColor.active} />
        Active
        <RadioButtonCheckedIcon className={tableStatusColor.inactive} />
        Not-Active
        <RadioButtonCheckedIcon className={tableStatusColor.nullValue} />
        Not Available
      </div>
    </Layout>
  );
}
